import React, {useState, useEffect, useRef, useCallback, useMemo} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CircleIcon from "@mui/icons-material/Circle";
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
	BLACK_TABLE,
	GRAY_TABLE,
	GRAY_TEXT_TABLE,
	GRAY_WEAK_TABLE,
	RED_TABLE,
	WHITE_TABLE,
	YELLOW_TABLE,
	YELLOW_WEAK_TABLE,
	YELLOW_INFO_UX,
	convertLabelToNumber,
	getScrollY,
	setScrollY,
	subtitleDREText,
	BLUE_INFO_UX,
	PURPLE_INFO_UX,
	BLUE_LIGHTER_UX,
	paperStyleFull,
	GRAY_LABEL_UX,
	waitForElm,
	ITENS_PAINEL,
	VERY_WEAK_GRAY_COMPARATIVO,
	DRE_PRE_SALVA_GRAY,
	LINE_TABLE,
	ROW_MAIN,
	stylesRow,
	WALPAPPER_THEME,
	BLUE_THEME,
	PAPER_PADDING_THEME,
	WHITE_ESCRITA_THEME, WHITE_THEME_BLACK,
	DASH_BAR_COLOR, GRID_BUTTONS_SPACING, GRAY_BG_UX, 
	getLastDate, GREEN_SUCCESS_UX
} from "../../shared/utils";
import Row from "../../components/Table/Row";
import RowData from "../../components/Table/RowData";
import api from "../../axios";
import Skeleton from "@mui/material/Skeleton";
import SelectStore from "../../components/SelectStore/SelectStore";
import TableHeader from "../../components/Table/TableHeader";
import { useReactToPrint } from "react-to-print";
import FilterWithTag from "../../components/Filter/FilterWithTag";
import GenericModal from "../../components/Modal/GenericModal";
import {
	changeOpenComponent
} from "../../actions/StoreActions";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {showSnackMessage} from "../../actions/SnackActions";
import ExportMenu from "../../components/Buttons/ExportMenu";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { steps, otherDreSteps } from "./TourDRE";
import {
	dreNotDetalharList,
	dreDetalharListIfood,
	labelsToPosition,
} from "./DREEnums";
import moment from "moment";
import "moment/locale/pt-br";
import LastModified from "../../components/LastModified/LastModified";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import GenericProjetada from "../../components/Modal/DREProjetada/GenericProjetada";
import InsertProjetada from "../../components/Modal/DREProjetada/InsertProjetada";
import GenericLastFaturamento from "../../components/Modal/DRELastMonth/GenericLastFaturamento";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SaveDRE from "../../components/Modal/SaveDRE";
import OptionsMenuComponent from "../../components/Buttons/OptionsMenuComponent";
import {
	changeStore,
} from "../../actions/StoreActions";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import OutboundIcon from "@mui/icons-material/Outbound";
import InfoModal from "../../components/Modal/InfoModal";

const DRE = () => {

	const [isDetalharDRE, setIsDetalharDRE] = useState(false);
	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [fixedValue, setFixedValue] = useState(0);
	const [columnId, setColumnId] = useState(0);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [fieldId, setFieldId] = useState("");
	const [comment, setComment] = useState("");
	const [loadingModal, setLoadingModal] = useState(false);
	const [faturamentoBrutoTotal, setFaturamentoBrutoTotal] = useState([]);
	const [faturamentoBrutoItens, setFaturamentoBrutoItens] = useState([]);
	const [faturamentoBrutoItensPercent, setFaturamentoBrutoItensPercent] = useState([]);
	const [faturamentoLiquidoItens, setFaturamentoLiquidoItens] = useState([]);
	const [faturamentoLiquidoItensPercent, setFaturamentoLiquidoItensPercent] = useState([]);
	const [faturamentoServicos, setFaturamentoServicos] = useState([]);
	const [faturamentoServicosPercent, setFaturamentoServicosPercent] = useState([]);
	const [devolucoes, setDevolucoes] = useState([]);
	const [devolucoesPercent, setDevolucoesPercent] = useState([]);
	const [descontos, setDescontos] = useState([]);
	const [descontosPercent, setDescontosPercent] = useState([]);
	const [freteParceira, setFreteParceira] = useState([]);
	const [freteParceiraPercent, setFreteParceiraPercent] = useState([]);
	const [fretes, setFretes] = useState([]);
	const [fretesPercent, setFretesPercent] = useState([]);
	const [comissoes, setComissoes] = useState([]);
	const [comissoesPercent, setComissoesPercent] = useState([]);
	const [receitasBrutas, setReceitasBrutas] = useState([]);
	const [taxasCartaoAppsDelivery, setTaxasCartaoAppsDelivery] = useState([]);
	const [taxasCartaoAppsDeliveryPercent, setTaxasCartaoAppsDeliveryPercent] = useState([]);
	const [taxasVendas, setTaxasVendas] = useState([]);
	const [taxasVendasPercent, setTaxasVendasPercent] = useState([]);
	const [rowsReceita, setRowsReceita] = useState([]);
	const [rowsReceitaPercent, setRowsReceitaPercent] = useState([]);
	const [markup, setMarkup] = useState([]);
	const [markupPercent, setMarkupPercent] = useState([]);
	const [cmvPorcentagem, setCmvPorcentagem] = useState([]);
	const [cmv, setCmv] = useState([]);
	const [cmvPercent, setCmvPercent] = useState([]);
	const [rowsCVM, setRowsCVM] = useState([]);
	const [rowsCVMPercent, setRowsCVMPercent] = useState([]);
	const [custosVariaveis, setCustosVariaveis] = useState([]);
	const [custosVariaveisPorcentagem, setCustosVariaveisPorcentagem] = useState([]);
	const [margemContribuicao, setMargemContribuicao] = useState([]);
	const [margemContribuicaoPorcentagem, setMargemContribuicaoPorcentagem] = useState([]);
	const [custosFixosReceitaPorcentagem, setCustosFixosReceitaPorcentagem] = useState([]);
	const [custosFixos, setCustosFixos] = useState([]);
	const [despesasPessoal, setDespesasPessoal] = useState([]);
	const [despesasPessoalPercent, setDespesasPessoalPercent] = useState([]);
	const [rowsDespesasPessoal, setRowsDespesasPessoal] = useState([]);
	const [rowsDespesasPessoalPercent, setRowsDespesasPessoalPercent] = useState([]);
	const [despesasVendasMarketing, setDespesasVendasMarketing] = useState([]);
	const [despesasVendasMarketingPercent, setDespesasVendasMarketingPercent] = useState([]);
	const [rowsVendasMarketing, setRowsVendasMarketing] = useState([]);
	const [rowsVendasMarketingPercent, setRowsVendasMarketingPercent] = useState([]);
	const [despesasOperacionais, setDespesasOperacionais] = useState([]);
	const [despesasOperacionaisPercent, setDespesasOperacionaisPercent] = useState([]);
	const [rowsOperacionais, setRowsOperacionais] = useState([]);
	const [rowsOperacionaisPercent, setRowsOperacionaisPercent] = useState([]);
	const [despesasIdentificar, setDespesasIdentificar] = useState([]);
	const [despesasIdentificarPercent, setDespesasIdentificarPercent] = useState([]);
	const [rowsIdentificar, setRowsIdentificar] = useState([]);
	const [rowsIdentificarPercent, setRowsIdentificarPercent] = useState([]);
	const [lucroPrejuizoPorcentagem, setLucroPrejuizoPorcentagem] = useState([]);
	const [lucroPrejuizoOperacional, setLucroPrejuizoOperacional] = useState([]);
	const [pontoEquilibrio, setPontoEquilibrio] = useState([]);
	const [pontoEquilibrioPercent, setPontoEquilibrioPercent] = useState([]);
	const [investimentos, setInvestimentos] = useState([]);
	const [investimentosPercent, setInvestimentosPercent] = useState([]);
	const [rowsInvestimentos, setRowsInvestimentos] = useState([]);
	const [rowsInvestimentosPercent, setRowsInvestimentosPercent] = useState([]);
	const [reembolso, setReembolso] = useState([]);
	const [reembolsoPercent, setReembolsoPercent] = useState([]);
	const [rowsReembolso, setRowsReembolso] = useState([]);
	const [rowsReembolsoPercent, setRowsReembolsoPercent] = useState([]);
	const [lucroPrejuizoLiquido, setLucroPrejuizoLiquido] = useState([]);
	const [lucroPrejuizoLiquidoPorcentagem, setLucroPrejuizoLiquidoPorcentagem] = useState([]);
	const [rowsEmprestimosHeader, setRowsEmprestimosHeader] = useState([]);
	const [rowsEmprestimosHeaderPercent, setRowsEmprestimosHeaderPercent] = useState([]);
	const [rowsEmprestimos, setRowsEmprestimos] = useState([]);
	const [rowsEmprestimosPercent, setRowsEmprestimosPercent] = useState([]);
	const [rowsLiquidoHeader, setRowsLiquidoHeader] = useState([]);
	const [transferencia, setTransferencia] = useState([]);
	const [transferenciaPercent, setTransferenciaPercent] = useState([]);
	const [rowsTransferencia, setRowsTransferencia] = useState([]);
	const [rowsTransferenciaPercent, setRowsTransferenciaPercent] = useState([]);
	const [cmo, setCmo] = useState([]);
	const [custoEntrega, setCustoEntrega] = useState([]);
	const [despesasEntregas, setDespesasEntregas] = useState([]);
	const [despesasEntregasPercent, setDespesasEntregasPercent] = useState([]);
	const [despesasEntregasPorcent, setDespesasEntregasPorcent] = useState([]);
	const [combustivelEntrega, setCombustivelEntrega] = useState([]);
	const [combustivelEntregaPercent, setCombustivelEntregaPercent] = useState([]);
	const [columns, setColumns] = useState([]);
	const [adquirencia, setTaxaAdquirencia] = useState([]);
	const [modelo, setModelo] = useState("restaurante");
	const [monthsFiltered, setMonthsFiltered] = useState([]);
	const [monthOptions, setMonthOptions] = useState([]);
	const [commentsList, setCommentsList] = useState([]);
	const [openInsertCommentModal, setOpenInsertCommentModal] = useState(false);
	const [openModalFixedValue, setOpenModalFixedValue] = useState(false);
	const [openViewCommentModal, setOpenViewCommentModal] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [selectedField, setSelectedField] = useState("");
	const [selectedLabel, setSelectedLabel] = useState({});
	const [scrollPosition, setScrollPosition] = useState(0);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const location = useLocation();
	const [shouldUpdateChild, setShouldUpdateChild] = useState(false);
	const [openProjetada, setOpenProjetada] = useState(false);
	const [openViewProjetada, setOpenViewProjetada] = useState(false);
	const [openInsertProjetada, setOpenInsertProjetada] = useState(false);
	const [selectedDate, setSelectedDate] = useState("");
	const [isEditProjetada, setIsEditProjetada] = useState(false);
	const [monthsNotRevisados, setMonthsNotRevisados] = useState([]);
	const [openSaveDre, setOpenSaveDre] = useState(false);
	const [selectedColumn, setSelectedColumn] = useState([]);
	const [percentActive, setPercentActive] = useState(false);

	const [openModalFat, setOpenModalFat] = useState(false);
	const [openModalFatMessage, setOpenModalFatMessage] = useState(false);
	const [lastFaturamentoDate, setlastFaturamentoDate] = useState("");
	const [lastFaturamentoValue, setLastFaturamentoValue] = useState(0);

	const [faturamentoProjetado, setFaturamentoProjetado] = useState(0);
	const [taxasimpostosProjetado, setTaxasimpostosProjetado] = useState(0);
	const [cmvPorcentagemProjetado, setCmvPorcentagemProjetado] = useState(0);
	const [despesasEntregasProjetado, setDespesasEntregasProjetado] = useState(0);
	const [despesasPessoalProjetado, setDespesasPessoalProjetado] = useState(0);
	const [despesasOperacionalProjetado, setDespesasOperacionalProjetado] = useState(0);
	const [lucroPrejuizoProjetado, setLucroPrejuizoProjetado] = useState(0);
	const [lucroPrejuizoPorcentagemProjetado, setLucroPrejuizoPorcentagemProjetado] = useState(0);
	const [cmoProjetado, setCmoProjetado] = useState(0);
	const [pontoEquilibrioProjetado, setPontoEquilibrioProjetado] = useState(0);

	const [faturamentoLiqTotalViewProjetado, setFaturamentoLiqTotalViewProjetado] = useState(0);
	const [taxasimpostosViewProjetado, setTaxasimpostosViewProjetado] = useState(0);
	const [cmvPorcentViewProjetado, setCmvPorcentViewProjetado] = useState(0);
	const [despesasEntregasViewProjetado, setDespesasEntregasViewProjetado] = useState(0);
	const [despesasPessoalViewProjetado, setDespesasPessoalViewProjetado] = useState(0);
	const [despesasOperacionalViewProjetado, setDespesasOperacionalViewProjetado] = useState(0);
	const [lucroPrejuizoLiqViewProjetado, setLucroPrejuizoLiqViewProjetado] = useState(0);
	const [lucroPrejuizoLiqPorcentViewProjetado, setLucroPrejuizoLiqPorcentViewProjetado] = useState(0);
	const [cmoViewProjetado, setCmoViewProjetado] = useState(0);
	const [pontoEquilibrioViewProjetado, setPontoEquilibrioViewProjetado] = useState(0);
	const [locationId, setLocationId] = useState();
	const [recalculada, setRecalculada] = useState(false);

	const dispatch = useDispatch();
	const [openTour, setOpenTour] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [open, setOpen] = useState(false);
	
	const lineRedirectDashboard = location?.state?.lineRedirectDashboard;
	const monthRedirectDashboard = location?.state?.monthRedirectDashboard;
	const storeId = useSelector(state => state.StoreReducer.storeId);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const isManager = useSelector(state => state.AuthReducer.isManager);
	const isOperator = useSelector(state => state.AuthReducer.isOperator);
	const isOpenDre = useSelector(state => state.StoreReducer.openComponent);
	const modeloSteps = modelo === "restaurante" ? steps : otherDreSteps;
	const filteredSteps = isAdmin ? modeloSteps : modeloSteps.filter(step => step.selector !== ".button_reload").filter(step => step.selector !== ".icon_projetada");
	const tourReceita = useMemo(() => (["linha_12", "linha_13", "linha_14", "linha_15", "linha_16"]), []);
	const tourCVM = useMemo(() => ([ "linha_20", "linha_21", "linha_22"]), []);
	const tourDespesasPessoal = useMemo(() => (["linha_34","linha_35","linha_36","linha_37","linha_38","linha_39","linha_40","linha_41","linha_42","linha_43","linha_44", "linha_45"]), []);
	const tourVendasMarketing = useMemo(() => (["linha_47","linha_48", "linha_49"]), []);
	const tourOperacionais = useMemo(() => (["linha_51","linha_52","linha_53","linha_54","linha_55","linha_56","linha_57","linha_58","linha_59","linha_60", "linha_61", "linha_62"]), []);
	const tourIdentificar = useMemo(() => (["linha_64", "linha_65"]), []);
	const tourInvestimentos = useMemo(() => (["linha_69", "linha_70", "linha_71"]), []);
	const tourReembolso = useMemo(() => (["linha_85", "linha_86", "linha_87", "linha_88", "linha_89"]), []);
	const tourEmprestimos = useMemo(() => (["linha_75","linha_76", "linha_77", "linha_78"]), []);
	const tourTransferencia = useMemo(() => (["linha_81","linha_82","linha_83", "linha_84"]), []);
	const tourOtherReceita = useMemo(() => (["linha_8", "linha_9", "linha_10"]), []);
	const tourOtherDespesasPessoal = useMemo(() => (["linha_16", "linha_17","linha_18","linha_19","linha_20","linha_21","linha_22","linha_23","linha_24","linha_25"]), []);
	const tourOtherVendasMarketing = useMemo(() => (["linha_27", "linha_28", "linha_29"]), []);
	const tourOtherOperacionais = useMemo(() => (["linha_31", "linha_32","linha_33","linha_34","linha_35","linha_36","linha_37","linha_38", "linha_39"]), []);
	const tourOtherIdentificar = useMemo(() => (["linha_41"]), []);
	const tourOtherInvestimentos = useMemo(() => (["linha_46"]), []);
	const tourOtherEmprestimos = useMemo(() => (["linha_48","linha_49","linha_50", "linha_51",]), []);
	const tourOtherTransferencia = useMemo(() => (["linha_53", "linha_54"]), []);

	const nav = useNavigate();

	useEffect(() => {
		if(storeId !== 0){
			getDRE();
		}
	}, [storeId]);

	useEffect(() => {
		if(!loading && scrollPosition !== 0){
			waitForElm(".table-row-data").then(() => {
				setScrollY(componentRef, scrollPosition);
			});
		}
	}, [scrollPosition, loading]);

	useEffect(() => {
		if (lastFaturamentoDate != "") {
			setOpenModalFat(true);
		}
	}, [lastFaturamentoDate]);

	const handleRefresh = useCallback((monthsFilter) => {
		setScrollPosition(0);
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			months: monthsFilter
		};
		api.RefreshDRE(dataRequest).then(() => {
			setLoading(false);
			setShouldUpdateChild(prevState => !prevState);
			getDRE();
		}).catch(() => {
			setLoading(false);
		});
	}, [storeId]);

	useEffect(() => {
        const query = new URLSearchParams(location.search);
        setLocationId(query.get("store_id"));
        if (locationId != 0 && locationId != null) {
            dispatch(changeStore(locationId));
        }
    }, [locationId]);

	const handleFilter = useCallback((months) => {
		setScrollPosition(0);
		setMonthsFiltered(months);
		getDRE(months);
	}, [storeId]);

	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const transformDateString = (dateString) => {
		return dateString.replace(/([a-z]{3})\/(\d{4})/, (_, monthAbbr, year) => {
			const monthNumber = moment(monthAbbr, "MMM").locale("pt-br").format("M");
			if (!monthNumber) {
				return dateString; // Return the original string if the month abbreviation is not recognized
			}
			const formattedMonth = monthNumber.toString().padStart(2, "0");
			return `${formattedMonth}/${year}`;
		});
	};

	const getDRE = (monthsFilter=[], position=0) => {
		setLoading(true);
		if (position !== 0){
			setScrollPosition(position);
		}
		if (monthRedirectDashboard != null){
			setScrollPosition(labelsToPosition[lineRedirectDashboard.toLowerCase()]);
			monthsFilter = [transformDateString(monthRedirectDashboard)];
		}
		const dataRequest = {
			loja_id: storeId,
			style: {
				BLACK_TABLE,
				GRAY_TABLE,
				GRAY_TEXT_TABLE,
				GRAY_WEAK_TABLE,
				RED_TABLE,
				WHITE_TABLE,
				YELLOW_TABLE,
				YELLOW_WEAK_TABLE,
				BLUE_LIGHTER_UX
			},
			months: monthsFilter
		};
		api.GetDRE(dataRequest).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			let dre = dataResponse.dre;
			let drePercentage = dataResponse.dre_percentage;
			let options = dataResponse.options;
			setPreviousMonths(dataResponse.filtered_months);
			setModelo(options.modelo_dre);
			setColumns(dataResponse.columns);
			setlastFaturamentoDate(dataResponse.last_month_zero);
			setMonthsNotRevisados(dataResponse.months_not_revisados);
			
			let _months = [];
			dataResponse.months.map((month) => {
				_months.push({value: month, label: month});
			});
			setMonthOptions(_months);
			if (options.modelo_dre === "restaurante") {
				setFaturamentoBrutoTotal(dre.dre_faturamento_total.values);
				setFaturamentoBrutoItens(dre.dre_faturamento_itens.values);
				setFaturamentoBrutoItensPercent(drePercentage.dre_faturamento_itens.values);
				setDevolucoes(dre.dre_devolucoes.values);
				setDevolucoesPercent(drePercentage.dre_devolucoes.values);
				setDescontos(dre.dre_descontos.values);
				setDescontosPercent(drePercentage.dre_descontos.values);
				setFreteParceira(dre.dre_valor_frete_parceira.values);
				setFreteParceiraPercent(drePercentage.dre_valor_frete_parceira.values);
				setFretes(dre.dre_fretes.values);
				setFretesPercent(drePercentage.dre_fretes.values);
				setComissoes(dre.dre_comissoes.values);
				setComissoesPercent(drePercentage.dre_comissoes.values);
				setFaturamentoLiquidoItens(dre.dre_faturamento_liquido_itens.values);
				setFaturamentoLiquidoItensPercent(drePercentage.dre_faturamento_liquido_itens.values);
				setFaturamentoServicos(dre.dre_faturamento_liquido_servicos.values);
				setFaturamentoServicosPercent(drePercentage.dre_faturamento_liquido_servicos.values);
				setDespesasEntregas(dre.dre_despesas_entregas.values);
				setDespesasEntregasPercent(drePercentage.dre_despesas_entregas.values);
				setDespesasEntregasPorcent(dre.dre_despesas_entregas_porcentagem.values);
				setCombustivelEntrega(dre.dre_combustivel_entregas.values);
				setCombustivelEntregaPercent(drePercentage.dre_combustivel_entregas.values);
				setReceitasBrutas(dre.dre_faturamento_liquido_total.values);
				setTaxasCartaoAppsDelivery(dre.dre_taxas_cartoes_apps_delivery.values);
				setTaxasCartaoAppsDeliveryPercent(drePercentage.dre_taxas_cartoes_apps_delivery.values);
				setTaxasVendas(dre.dre_taxa_vendas.values);
				setTaxasVendasPercent(drePercentage.dre_taxa_vendas.values);
				setRowsReceita(dre.dre_taxa_vendas.data);
				setRowsReceitaPercent(drePercentage.dre_taxa_vendas.data);
				setMarkup(dre.dre_markup.values);
				setMarkupPercent(drePercentage.dre_markup.values);
				setCmvPorcentagem(dre.dre_cmv_porcentagem.values);
				setCmv(dre.dre_cmv.values);
				setCmvPercent(drePercentage.dre_cmv.values);
				setRowsCVM(dre.dre_cmv.data);
				setRowsCVMPercent(drePercentage.dre_cmv.data);
				setCustosVariaveis(dre.dre_custos_variaveis.values);
				setCustosVariaveisPorcentagem(dre.dre_custos_variaveis_porcentagem.values);
				setMargemContribuicao(dre.dre_margem_contribuicao.values);
				setMargemContribuicaoPorcentagem(dre.dre_margem_contribuicao_porcentagem.values);
				setCustosFixosReceitaPorcentagem(dre.dre_custos_fixos_receita_porcentagem.values);
				setCustosFixos(dre.dre_custos_fixos.values);
				setDespesasPessoal(dre.dre_despesas_com_pessoal.values);
				setDespesasPessoalPercent(drePercentage.dre_despesas_com_pessoal.values);
				setRowsDespesasPessoal(dre.dre_despesas_com_pessoal.data);
				setRowsDespesasPessoalPercent(drePercentage.dre_despesas_com_pessoal.data);
				setDespesasVendasMarketing(dre.dre_despesas_vendas_marketing.values);
				setDespesasVendasMarketingPercent(drePercentage.dre_despesas_vendas_marketing.values);
				setRowsVendasMarketing(dre.dre_despesas_vendas_marketing.data);
				setRowsVendasMarketingPercent(drePercentage.dre_despesas_vendas_marketing.data);
				setDespesasOperacionais(dre.dre_despesas_op_admin.values);
				setDespesasOperacionaisPercent(drePercentage.dre_despesas_op_admin.values);
				setRowsOperacionais(dre.dre_despesas_op_admin.data);
				setRowsOperacionaisPercent(drePercentage.dre_despesas_op_admin.data);
				setDespesasIdentificar(dre.dre_despesas_identificar.values);
				setDespesasIdentificarPercent(drePercentage.dre_despesas_identificar.values);
				setRowsIdentificar(dre.dre_despesas_identificar.data);
				setRowsIdentificarPercent(drePercentage.dre_despesas_identificar.data);
				setLucroPrejuizoPorcentagem(dre.dre_lucro_prejuizo_operacional_porcentagem.values);
				setLucroPrejuizoOperacional(dre.dre_lucro_prejuizo_operacional.values);
				setPontoEquilibrio(dre.dre_ponto_equilibrio.values);
				setPontoEquilibrioPercent(drePercentage.dre_ponto_equilibrio.values);
				setInvestimentos(dre.dre_investimentos.values);
				setInvestimentosPercent(drePercentage.dre_investimentos.values);
				setRowsInvestimentos(dre.dre_investimentos.data);
				setRowsInvestimentosPercent(drePercentage.dre_investimentos.data);
				setReembolso(dre.dre_reembolso_ifood.values);
				setReembolsoPercent(drePercentage.dre_reembolso_ifood.values);
				setRowsReembolso(dre.dre_reembolso_ifood.data);
				setRowsReembolsoPercent(drePercentage.dre_reembolso_ifood.data);
				setLucroPrejuizoLiquido(dre.dre_lucro_prejuizo_liquido.values);
				setLucroPrejuizoLiquidoPorcentagem(dre.dre_lucro_prejuizo_liquido_porcentagem.values);
				setRowsEmprestimosHeader(dre.dre_emprestimos.values);
				setRowsEmprestimosHeaderPercent(drePercentage.dre_emprestimos.values);
				setRowsEmprestimos(dre.dre_emprestimos.data);
				setRowsEmprestimosPercent(drePercentage.dre_emprestimos.data);
				setRowsLiquidoHeader(dre.dre_resultado_liquido.values);
				setTransferencia(dre.dre_transferencias.values);
				setTransferenciaPercent(drePercentage.dre_transferencias.values);
				setRowsTransferencia(dre.dre_transferencias.data);
				setRowsTransferenciaPercent(drePercentage.dre_transferencias.data);
				setCustoEntrega(dre.dre_custo_entrega_reais.values);
				setCmo(dre.dre_cmo.values);
			}
			else{
				setFaturamentoBrutoTotal(dre.other_dre_faturamento_total.values);
				setFaturamentoBrutoItens(dre.other_dre_faturamento_itens.values);
				setDescontos(dre.other_dre_descontos.values);
				setFaturamentoLiquidoItens(dre.other_dre_faturamento_liquido_itens.values);
				setFaturamentoServicos(dre.other_dre_faturamento_liquido_servicos.values);
				setComissoes(dre.other_dre_comissoes.values);
				setTaxaAdquirencia(dre.other_dre_adquirencias_boletos.values);
				setRowsReceita(dre.other_dre_taxa_vendas.data);
				setMargemContribuicao(dre.other_dre_margem_contribuicao.values);
				setMargemContribuicaoPorcentagem(dre.other_dre_margem_contribuicao_porcentagem.values);
				setCustosFixos(dre.other_dre_custos_fixos.values);
				setCustosFixosReceitaPorcentagem(dre.other_dre_custos_fixos_receita_porcentagem.values);
				setDespesasPessoal(dre.other_dre_despesas_com_pessoal.values);
				setRowsDespesasPessoal(dre.other_dre_despesas_com_pessoal.data);
				setDespesasVendasMarketing(dre.other_dre_despesas_vendas_marketing.values);
				setRowsVendasMarketing(dre.other_dre_despesas_vendas_marketing.data);
				setDespesasOperacionais(dre.other_dre_despesas_op_admin.values);
				setRowsOperacionais(dre.other_dre_despesas_op_admin.data);
				setDespesasIdentificar(dre.other_dre_despesas_identificar.values);
				setRowsIdentificar(dre.other_dre_despesas_identificar.data);
				setLucroPrejuizoOperacional(dre.other_dre_lucro_prejuizo_operacional.values);
				setLucroPrejuizoPorcentagem(dre.other_dre_lucro_prejuizo_operacional_porcentagem.values);
				setPontoEquilibrio(dre.other_dre_ponto_equilibrio.values);
				setInvestimentos(dre.other_dre_investimentos.values);
				setRowsInvestimentos(dre.other_dre_investimentos.data);
				setLucroPrejuizoLiquido(dre.other_dre_lucro_prejuizo_liquido.values);
				setRowsEmprestimos(dre.other_dre_emprestimos.data);
				setRowsLiquidoHeader(dre.other_dre_resultado_liquido.values);
				setRowsTransferencia(dre.other_dre_transferencias.data);
			}
		}).catch(() => {
			setLoading(false);
			setColumns([]);
		});
	};

	const handleProvision = useCallback(() => {
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			field: fieldId,
			month: columnId
		};
		api.PutDREProvision(dataRequest).then(() => {
			setLoading(false);
			getDRE(monthsFiltered);
		}).catch(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, fieldId, columnId, monthsFiltered]);

	const handleOpenModal = useCallback( (data, index, field, comments) => {
		let column = columns[index + 1];
		let label = column.label;
		let newLabel = convertLabelToNumber(label);
		
		setCommentsList(comments);
		setFixedValue(data.value);
		setColumnId(newLabel);
		setFieldId(field);
		setComment(data.comment);
		setSelectedField(field);
		setSelectedLabel(newLabel);
		setSelectedColumn(column);
		if (isAdmin || isManager){			
			setOpenModal(true);
		} else {
			handleDetalharValor(newLabel, field);
		}
	}, [isAdmin, isManager, columns]);

	const handleDetalharValor = useCallback((columnId=selectedLabel, fieldId=selectedField) => {
		if (dreNotDetalharList.includes(fieldId)){
			dispatch(showSnackMessage({message: "Impossivel detalhar categoria.", severity: "warning"}));
		} else {
			if (fieldId == "(-) Taxas de Cartão e Apps de Delivery") {
				dispatch(changeOpenComponent(false));
				setIsDetalharDRE(true);
				nav("/painel", {state:{detalhar:isDetalharDRE}});
			}
			else {
				const baseUrl = "/detalha_";
				const urlSuffix = dreDetalharListIfood.includes(fieldId) ? "ifood" : "ca";
				const url = `${baseUrl}${urlSuffix}`;
				const params = { lojas: storeId, columnId, fieldId, table: "DRE"};
				const queryString = new URLSearchParams(params).toString();
				const fullUrl = `${url}?${queryString}`;
				window.open(fullUrl, "_blank");
			}
			}
		}
	, [storeId, selectedLabel, selectedField]);

	const sendMetadata = useCallback((metadata, value, comment) => {
		setLoadingModal(true);
		let position = getScrollY(componentRef);
		const dataRequest = {
			loja_id: storeId ,
			field: fieldId,
			month: columnId,
			metadata: metadata,
			value: parseFloat(value),
			comment: comment
		};
		api.DREMetadata(dataRequest).then(() => {
			setLoadingModal(false);
			if (metadata === "comment"){
				dispatch(showSnackMessage({message: "Comentário salvo com sucesso"}));
				setOpenInsertCommentModal(false);
			}
			else if (metadata === "fixed"){
				dispatch(showSnackMessage({message: "Valor fixo salvo com sucesso"}));
				setOpenModalFixedValue(false);
			}
			getDRE(monthsFiltered, position);
		}).catch((e) => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			if (e.response.status === 403) {
				dispatch(showSnackMessage({message: e.response.data, severity: "error"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
		});
	}, [storeId, fieldId, columnId, monthsFiltered]);

	const editComment = useCallback((comment, commentId) => {
		setLoadingModal(true);
		setIsEdit(false);
		let position = getScrollY(componentRef);
		const dataRequest = {
			loja_id: storeId,
			comment: comment,
			comment_id: commentId
		};
		api.editCommentDRE(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Comentário editado com sucesso"}));
			getDRE(monthsFiltered, position);
		}).catch(() => {
			setLoadingModal(false);
			setOpenInsertCommentModal(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [monthsFiltered]);

	const delMetadata = useCallback((metadata, commentId) =>{
		setLoadingModal(true);
		let dataRequest = {};
		let position = getScrollY(componentRef);
		if (metadata === "comment"){
			dataRequest = {
				loja_id: storeId,
				metadata: metadata,
				comment_id: commentId
			};
		} else {
			dataRequest = {
				loja_id: storeId,
				month: columnId,
				field: fieldId,
				metadata: metadata
			};
		}
		api.delMetadataDRE(dataRequest).then(() => {
			setLoadingModal(false);
			setOpenModal(false);
			if (metadata === "comment"){
				dispatch(showSnackMessage({message: "Comentário deletado com sucesso!"}));
				setOpenViewCommentModal(false);
			}
			else if (metadata === "fixed"){
				dispatch(showSnackMessage({message: "Valor fixo deletado com sucesso!"}));
				setOpenModalFixedValue(false);
			}
			else if (metadata === "both"){
				dispatch(showSnackMessage({message: "Comentário e valor fixo deletados com sucesso!"}));
			}
			getDRE(monthsFiltered, position);
		}).catch((e) => {
			setLoadingModal(false);
			setOpenModal(false);
			if (e.response.status === 403) {
				dispatch(showSnackMessage({message: e.response.data, severity: "error"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
		});
	}, [storeId, fieldId, columnId, monthsFiltered]);

	const handleExportExcel = useCallback(() => {
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			date_list: monthsFiltered,
		};
		api.ExportDreExcel(dataRequest).then(response => {
			setLoading(false);
			let url = response.data;
			window.open(url, "_blank");
		}).catch(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	});

	const handleAbrirTodos = () => {
		dispatch(changeOpenComponent(!isOpenDre));
	};

	const changeOpen = () => {  
		setOpen(true);
		dispatch(changeOpenComponent(true));
		setStartAt(0);
		setOpenTour(!openTour);
	};

	const openTourSelected = (index) => {
		dispatch(changeOpenComponent(true));
		setStartAt(index);
		setOpenTour(!openTour);
	};

	const handleOpenProjetada = (date) => {
		if (!isAdmin) {
			getMetasDREProjetada();
		} else {
			setOpenProjetada(true);
			setSelectedDate(date.label);
			setFaturamentoProjetado(faturamentoBrutoTotal[0][date.label]["value"]);
			setTaxasimpostosProjetado(taxasVendas[0][date.label]["value"]);
			setCmvPorcentagemProjetado(cmvPorcentagem[0][date.label]["value"]);
			setDespesasEntregasProjetado(despesasEntregas[0][date.label]["value"]);
			setDespesasPessoalProjetado(despesasPessoal[0][date.label]["value"]);
			setDespesasOperacionalProjetado(despesasOperacionais[0][date.label]["value"]);
			setLucroPrejuizoProjetado(lucroPrejuizoOperacional[0][date.label]["value"]);
			setLucroPrejuizoPorcentagemProjetado(lucroPrejuizoPorcentagem[0][date.label]["value"]);
			setCmoProjetado(custoEntrega[0][date.label]["value"]);
			setPontoEquilibrioProjetado(pontoEquilibrio[0][date.label]["value"]);
			setCmoProjetado(cmo[0][date.label]["value"]);
		}
	};

	const postDREProjetada = useCallback(() => {
		setOpenInsertProjetada(false);
		setLoading(true);
		const dataRequest = {
			loja_id: storeId,
			date: selectedDate,
			faturamento_bruto: faturamentoProjetado,
			taxas_impostos: taxasimpostosProjetado,
			cmv_porcentagem: cmvPorcentagemProjetado,
			despesas_entregas: despesasEntregasProjetado,
			despesas_pessoal: despesasPessoalProjetado,
			despesas_operacional: despesasOperacionalProjetado
		};
		api.PostDREProjetada(dataRequest).then(() => {
			setLoading(false);
			setOpenInsertProjetada(false);
			getDRE();
			dispatch(showSnackMessage({message: "DRE Projetada salva com sucesso!"}));
		}).catch(() => {
			setLoading(false);
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	});

	const getDREProjetadas = useCallback(() => {
		const dataRequest = {
			loja_id: storeId,
			selected_date: selectedDate
		};
		api.GetDREProjetada(dataRequest).then(response => {
			setOpenViewProjetada(true);
			let dataResponse = response.data;
			setFaturamentoLiqTotalViewProjetado(dataResponse.fat_liq_total_projetado);
			setTaxasimpostosViewProjetado(dataResponse.taxas_impostos_projetado);
			setCmvPorcentViewProjetado(dataResponse.cmv_porcent_projetado);
			setDespesasEntregasViewProjetado(dataResponse.despesas_entregas_projetado);
			setDespesasPessoalViewProjetado(dataResponse.despesas_pessoal_projetado);
			setDespesasOperacionalViewProjetado(dataResponse.despesas_op_projetado);
			setLucroPrejuizoLiqViewProjetado(dataResponse.lucro_prej_op_projetado);
			setLucroPrejuizoLiqPorcentViewProjetado(dataResponse.lucro_prej_op_porcent_projetado);
			setCmoViewProjetado(dataResponse.cmo_projetado);
			setPontoEquilibrioViewProjetado(dataResponse.ponto_equilibrio_projetado);

		}).catch((e) => {
			if (e.response.status === 404) {
				dispatch(showSnackMessage({message: "Não há DRE Projetada para essa data!", severity: "warning"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
		});
	}, [storeId, selectedDate]);

	const postDRELastFaturamento = useCallback((value) => {
		if (isAdmin) {
			setOpenModalFat(false);
			setLoading(true);
			const dataRequest = {
				loja_id: storeId ,
				field: "Faturamento Bruto de Itens",
				month: getLastDate(),
				metadata: "fixed",
				value: parseFloat(value),
				is_provisao: true
			};
			api.DREMetadata(dataRequest).then(() => {
				setLoading(false);
				setOpenModalFat(false);
				getDRE();
				setOpenModalFatMessage(true);
				dispatch(showSnackMessage({message: "Faturamento salvo com sucesso!"}));
			}).catch(() => {
				setLoading(false);
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			});
		}
		else {
			setOpenModalFat(false);
			setLoading(true);
			const dataRequest = {
				loja_id: storeId ,
				month: getLastDate(),
				value: parseFloat(value),
			};
			api.InsertFaturamentoDRE(dataRequest).then(() => {
				setLoading(false);
				setOpenModalFat(false);
				getDRE();
				setOpenModalFatMessage(true);
				dispatch(showSnackMessage({message: "Faturamento salvo com sucesso!"}));
			}).catch(() => {
				setLoading(false);
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			});
		}
	}, [storeId]);

	const getMetasDREProjetada = useCallback(() => {
		const dataRequest = {
			loja_id: storeId,
			selected_date: monthOptions[0]["label"],
			is_metas: true
		};
		api.GetDREProjetada(dataRequest).then(response => {
			setIsEditProjetada(true);
			setOpenInsertProjetada(true);
			let dataResponse = response.data;
			setFaturamentoProjetado(dataResponse.fat_liq_total_projetado);
			setTaxasimpostosProjetado(dataResponse.taxas_impostos_projetado);
			setCmvPorcentagemProjetado(dataResponse.cmv_porcent_projetado);
			setDespesasEntregasProjetado(dataResponse.despesas_entregas_projetado);
			setDespesasPessoalProjetado(dataResponse.despesas_pessoal_projetado);
			setDespesasOperacionalProjetado(dataResponse.despesas_op_projetado);
		}).catch((e) => {
			if (e.response.status === 404) {
				dispatch(showSnackMessage({message: "Não há nenhuma meta criada para o próximo mês!", severity: "warning"}));
			} else {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			}
		});
	}, [storeId, selectedDate, monthOptions]);

	const handleSaveDRE = useCallback((column, action) => {
		const dataRequest = {
			loja_id: storeId,
			date: column.label,
			action: action
		};

		if (column?.revisada === false && (action == "salvar" || action == "pre_salvar")) {
			if (action == "salvar" || action == "remove_salvar") {
				setLoading(true);
				setOpenSaveDre(false);
			}

			api.PostSaveDRE(dataRequest).then((response) => {
				dispatch(showSnackMessage({message: response.data.message}));
				setOpenSaveDre(false);
				setRecalculada(response.data.recalculada);
				getDRE(monthsFiltered);
				setLoading(false);
			}).catch(() => {
				setLoading(false);
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			});
		} else {
			api.DeleteSaveDRE(dataRequest).then((response) => {
				dispatch(showSnackMessage({message: response.data.message}));
				setOpenSaveDre(false);
				getDRE(monthsFiltered);
			}).catch(() => {
				dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
			});
		
		}
	}, [storeId]);

	const handleOpenSaveDre = (column) => {
		if (column?.revisada && (isOperator && (!isAdmin || !isManager))) {
			setOpenSaveDre(false);
		}
		else {
			setSelectedColumn(column);
			setOpenSaveDre(true);
		}
	};

	const changeStatePercent = () => {
		setPercentActive(!percentActive);
	};

	const sendDreSaved = useCallback(() => {
		const dataRequest = {
			"loja_id": storeId,
			"data": selectedColumn.id,
		};
		api.SendDREMessage(dataRequest).then(() => {
			getDRE(monthsFiltered);
			dispatch(showSnackMessage({message: "DRE enviada com sucesso!"}));
		}).catch(() => {
			dispatch(showSnackMessage({message: "Algo deu errado! Tente novamente mais tarde", severity: "error"}));
		});
	}, [storeId, selectedColumn]);

	return (
		<div className="main">
			<LastModified title="DRE - Demonstração do Resultado do Exercício" shouldUpdate={shouldUpdateChild}/>
			{
				(isAdmin || isOperator || isManager) ? (
					<React.Fragment>
						<GenericLastFaturamento
							openModalFat={openModalFat}
							setOpenModalFat={setOpenModalFat}
							setOpenModalFatMessage={setOpenModalFatMessage}
							openModalFatMessage={openModalFatMessage}
							lastFaturamentoDate={lastFaturamentoDate}
							setLastFaturamentoValue={setLastFaturamentoValue}
							lastFaturamentoValue={lastFaturamentoValue}
							postDRELastFaturamento={postDRELastFaturamento}
						/>
						<SaveDRE
							openSaveDre={openSaveDre}
							setOpenSaveDre={setOpenSaveDre}
							selectedColumn={selectedColumn}
							handleSaveDRE={handleSaveDRE}
							sendDreSaved={sendDreSaved}
							monthsFiltered={monthsFiltered}
							getDRE={getDRE}
						/>
						<InfoModal
							openModal={recalculada}
							setOpenModal={setRecalculada}
							infoTitle="DRE atualizada"
							information={`A DRE ${selectedColumn.label} não foi salva porque recalculamos ela devido à inclusão de relatórios que não estavam sendo considerados no cálculo anterior. Pedimos que revise os dados e salve a DRE novamente.`}
						/>
						{((isAdmin || isManager) && !isOperator) && (
							<React.Fragment>
								<InsertProjetada
									openInsertProjetada={openInsertProjetada}
									setOpenInsertProjetada={setOpenInsertProjetada}
									faturamentoProjetado={faturamentoProjetado}
									setFaturamentoProjetado={setFaturamentoProjetado}
									taxasimpostosProjetado={taxasimpostosProjetado}
									setTaxasimpostosProjetado={setTaxasimpostosProjetado}
									cmvPorcentagemProjetado={cmvPorcentagemProjetado}
									setCmvPorcentagemProjetado={setCmvPorcentagemProjetado}
									despesasEntregasProjetado={despesasEntregasProjetado}
									setDespesasEntregasProjetado={setDespesasEntregasProjetado}
									despesasPessoalProjetado={despesasPessoalProjetado}
									setDespesasPessoalProjetado={setDespesasPessoalProjetado}
									despesasOperacionalProjetado={despesasOperacionalProjetado}
									setDespesasOperacionalProjetado={setDespesasOperacionalProjetado}
								/>
								<GenericModal
									openModal={openModal}
									setOpenModal={setOpenModal}
									fixedValue={fixedValue}
									setFixedValue={setFixedValue}
									setOpenModalFixedValue={setOpenModalFixedValue}
									openModalFixedValue={openModalFixedValue}
									openViewCommentModal={openViewCommentModal}
									openInsertCommentModal={openInsertCommentModal}
									setOpenViewCommentModal={setOpenViewCommentModal}
									setOpenInsertCommentModal={setOpenInsertCommentModal}
									commentsList={commentsList}
									comment={comment}
									setComment={setComment}
									editComment={editComment}
									loadingModal={loadingModal}
									delMetadata={delMetadata}
									sendMetadata={sendMetadata}
									isEdit={isEdit}
									setIsEdit={setIsEdit}
									handleDetalharValor={handleDetalharValor}
									selectedField={selectedField}
									selectedColumn={selectedColumn}
									handleProvision={handleProvision}
									component="dre"
								/>
								<GenericProjetada
									openProjetada={openProjetada}
									setOpenProjetada={setOpenProjetada}
									selectedDate={selectedDate}
									faturamentoProjetado={faturamentoProjetado}
									setFaturamentoProjetado={setFaturamentoProjetado}
									taxasimpostosProjetado={taxasimpostosProjetado}
									setTaxasimpostosProjetado={setTaxasimpostosProjetado}
									cmvPorcentagemProjetado={cmvPorcentagemProjetado}
									setCmvPorcentagemProjetado={setCmvPorcentagemProjetado}
									despesasEntregasProjetado={despesasEntregasProjetado}
									setDespesasEntregasProjetado={setDespesasEntregasProjetado}
									despesasPessoalProjetado={despesasPessoalProjetado}
									setDespesasPessoalProjetado={setDespesasPessoalProjetado}
									despesasOperacionalProjetado={despesasOperacionalProjetado}
									setDespesasOperacionalProjetado={setDespesasOperacionalProjetado}
									postDREProjetada={postDREProjetada}
									setOpenViewProjetada={setOpenViewProjetada}
									openViewProjetada={openViewProjetada}
									setOpenInsertProjetada={setOpenInsertProjetada}
									openInsertProjetada={openInsertProjetada}
									getDREProjetadas={getDREProjetadas}
									faturamentoLiqTotalViewProjetado={faturamentoLiqTotalViewProjetado}
									taxasimpostosViewProjetado={taxasimpostosViewProjetado}
									cmvPorcentViewProjetado={cmvPorcentViewProjetado}
									despesasEntregasViewProjetado={despesasEntregasViewProjetado}
									despesasPessoalViewProjetado={despesasPessoalViewProjetado}
									despesasOperacionalViewProjetado={despesasOperacionalViewProjetado}
									lucroPrejuizoLiqViewProjetado={lucroPrejuizoLiqViewProjetado}
									lucroPrejuizoProjetado={lucroPrejuizoProjetado}
									lucroPrejuizoLiqPorcentViewProjetado={lucroPrejuizoLiqPorcentViewProjetado}
									lucroPrejuizoPorcentagemProjetado={lucroPrejuizoPorcentagemProjetado}
									cmoViewProjetado={cmoViewProjetado}
									cmoProjetado={cmoProjetado}
									pontoEquilibrioViewProjetado={pontoEquilibrioViewProjetado}
									pontoEquilibrioProjetado={pontoEquilibrioProjetado}
									getMetasDREProjetada={getMetasDREProjetada}
									isEdit={isEditProjetada}
									setIsEdit={setIsEditProjetada}
								/>
							</React.Fragment>
						)}
						{(isOperator && !isAdmin) && (
							<InsertProjetada
								openInsertProjetada={openInsertProjetada}
								setOpenInsertProjetada={setOpenInsertProjetada}
								faturamentoProjetado={faturamentoProjetado}
								setFaturamentoProjetado={setFaturamentoProjetado}
								taxasimpostosProjetado={taxasimpostosProjetado}
								setTaxasimpostosProjetado={setTaxasimpostosProjetado}
								cmvPorcentagemProjetado={cmvPorcentagemProjetado}
								setCmvPorcentagemProjetado={setCmvPorcentagemProjetado}
								despesasEntregasProjetado={despesasEntregasProjetado}
								setDespesasEntregasProjetado={setDespesasEntregasProjetado}
								despesasPessoalProjetado={despesasPessoalProjetado}
								setDespesasPessoalProjetado={setDespesasPessoalProjetado}
								despesasOperacionalProjetado={despesasOperacionalProjetado}
								setDespesasOperacionalProjetado={setDespesasOperacionalProjetado}
							/>
						)}
					</React.Fragment>
				) : (
					<React.Fragment>
						<InsertProjetada
							openInsertProjetada={openInsertProjetada}
							setOpenInsertProjetada={setOpenInsertProjetada}
							faturamentoProjetado={faturamentoProjetado}
							setFaturamentoProjetado={setFaturamentoProjetado}
							taxasimpostosProjetado={taxasimpostosProjetado}
							setTaxasimpostosProjetado={setTaxasimpostosProjetado}
							cmvPorcentagemProjetado={cmvPorcentagemProjetado}
							setCmvPorcentagemProjetado={setCmvPorcentagemProjetado}
							despesasEntregasProjetado={despesasEntregasProjetado}
							setDespesasEntregasProjetado={setDespesasEntregasProjetado}
							despesasPessoalProjetado={despesasPessoalProjetado}
							setDespesasPessoalProjetado={setDespesasPessoalProjetado}
							despesasOperacionalProjetado={despesasOperacionalProjetado}
							setDespesasOperacionalProjetado={setDespesasOperacionalProjetado}
						/>
						<GenericLastFaturamento
							openModalFat={openModalFat}
							setOpenModalFat={setOpenModalFat}
							setOpenModalFatMessage={setOpenModalFatMessage}
							openModalFatMessage={openModalFatMessage}
							lastFaturamentoDate={lastFaturamentoDate}
							setLastFaturamentoValue={setLastFaturamentoValue}
							lastFaturamentoValue={lastFaturamentoValue}
							postDRELastFaturamento={postDRELastFaturamento}
						/>
					</React.Fragment>
				)
			}
			<Paper sx={{...paperStyleFull, backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && LINE_TABLE, backgroundImage: temaEscuro && "none"}}>
				{loading ? (
					[...Array(15).keys()].map((i,d) =>{
						return(
							<Skeleton key={d} height={50}/>
						);
					})
				) : (
					storeId === 0 ?
						(
							<SelectStore/>
						): (
							<React.Fragment>
								<Grid container spacing={2} data-testid="dre">
									<Grid item xs={12}>
										<Grid container sx={{display: "flex", alignItems: "center"}}>
											<Grid item sx={{ width: "490px"}}>
												<FilterWithTag
													options={monthOptions}
													handleSubmit={handleFilter}
													setOptions={setMonthsFiltered}
													monthsFiltered={monthsFiltered}
													previousValue={previousMonths}
													className="button_filter"
												/>
											</Grid>
											<Grid item xs>
												<Grid container spacing={GRID_BUTTONS_SPACING} sx={{display: "flex", justifyContent: "end"}}>
													<Grid item>
														<OptionsMenuComponent
															isAdmin={isAdmin}
															handleRefresh={handleRefresh}
															monthsNotRevisados={monthsNotRevisados}
															setMonthOptions={setMonthOptions}
															monthsFiltered={monthsFiltered}
															setMonthsFiltered={setMonthsFiltered}
															optionFilter
															disabled={monthsNotRevisados.length > 0 ? false : true}
															changeStatePercent={changeStatePercent}
															className="button_options"
															component="DRE"
														/>
													</Grid>
													<Grid item>
														<ExportMenu print={handlePrint} excel={handleExportExcel} className="button_exportar"/>
													</Grid>
													<Grid item>
														<IconButton style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
															<LightbulbIcon style={{color: GRAY_LABEL_UX, ...styles.iconSize, padding: "1px"}}/>
														</IconButton>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12}>
												<Grid spacing={2} container>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{...styles.iconSize, color: YELLOW_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Valor Manual</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{...styles.iconSize, color: BLUE_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Provisão</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CircleIcon className="circles" style={{...styles.iconSize, color: PURPLE_INFO_UX, }}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Comentário</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<WatchLaterIcon className="circles" style={{...styles.iconSize, color: temaEscuro ? VERY_WEAK_GRAY_COMPARATIVO : DRE_PRE_SALVA_GRAY}}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Pré-revisada</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<CheckCircleIcon style={{...styles.iconSize, color: GREEN_SUCCESS_UX}}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Revisada</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<OutboundIcon style={{...styles.iconSize, color: DASH_BAR_COLOR}}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Relatório enviado</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<SupervisedUserCircleIcon style={{...styles.iconSize, color: temaEscuro ? VERY_WEAK_GRAY_COMPARATIVO : DRE_PRE_SALVA_GRAY}}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Reunião Pendente</a>
															</Grid>
														</Grid>
													</Grid>
													<Grid item>
														<Grid container spacing={.2}>
															<Grid item sx={{display: "flex", alignItems: "center"}}>
																<SupervisedUserCircleIcon style={{...styles.iconSize, color: DASH_BAR_COLOR}}/>
															</Grid>
															<Grid item sx={{...subtitleDREText, color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
																<a>Reunião Feita</a>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<TableContainer ref={componentRef} className="table-container">
											<Tour
												steps={filteredSteps}
												isOpen={openTour}
												onRequestClose={changeOpen}
												accentColor={DASH_BAR_COLOR}
												startAt={startAt}
												rounded={5}
												showNavigation={false}
												inViewThreshold={60}
												className={temaEscuro && "tour"}
												style={{
													...{ maxWidth: "none" },
												}}
												badgeContent={(curr, tot) => `${curr}/${tot}`}
											/>
											{modelo === "restaurante" ? (
												<Table stickyHeader aria-label="sticky table">
													<TableHead>
														<TableRow sx={{alignItems: "center"}}>
															{columns.map((column, idx) => (
																<TableCell
																	key={column.label}
																	align={column.align}
																	style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold"},
																		...{ minWidth: column.minWidth },
																		...{ backgroundColor: temaEscuro && WALPAPPER_THEME},
																		...{color: temaEscuro && LINE_TABLE},
																		...{padding: 0}
																	}}
																>
																	{(column.label !== "Total" && column.label !== "Período") ? (
																		<Grid container sx={{display: "flex", justifyContent: "center", cursor: "pointer"}}>
																			<Grid item>
																				<Grid container onClick={() => handleOpenSaveDre(column)}>
																					<Grid item>
																						{column.label}
																					</Grid>
																						{(column?.revisada && column?.nps_status == "sem_nps") ? (
																							<Grid item>
																								<CheckCircleIcon style={{marginTop: "3px", fontSize: "18px", color: GREEN_SUCCESS_UX}}/>
																							</Grid>
																						) : column?.pre_revisada ? (
																							<Grid item>
																								<WatchLaterIcon style={{marginTop: "3px", fontSize: "18px", color: temaEscuro ? VERY_WEAK_GRAY_COMPARATIVO : DRE_PRE_SALVA_GRAY}}/>
																							</Grid>
																						) : column?.revisada && column?.nps_status == "relatorio_enviado" ? (
																							<Grid item>
																								<OutboundIcon style={{marginTop: "3px", fontSize: "18px", color: DASH_BAR_COLOR}}/>
																							</Grid>
																						) : column?.revisada && column?.nps_status == "reuniao_feita" ? (
																							<Grid item>
																								<SupervisedUserCircleIcon style={{marginTop: "3px", fontSize: "18px", color: DASH_BAR_COLOR}}/>
																							</Grid>
																						) : column?.revisada && column?.nps_status == "reuniao_pendente" ? (
																							<Grid item>
																								<SupervisedUserCircleIcon style={{marginTop: "3px", fontSize: "18px", color: temaEscuro ? VERY_WEAK_GRAY_COMPARATIVO : DRE_PRE_SALVA_GRAY}}/>
																							</Grid>) : null}
																				</Grid>
																			</Grid>
																			<Grid item>
																				{idx === (columns.length - 2) ? (
																					<BookmarkAddIcon
																						onClick={() => handleOpenProjetada(column)}
																						className="icon_projetada"
																						style={{marginTop: "3px", fontSize: "18px"}}
																					/>
																				): null}
																			</Grid>
																		</Grid>
																		) : (
																			column.label
																		)
																	}	
																</TableCell>
															))}
															<TableCell
																style={{...stylesRow, paddingTop: "22px", backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && WHITE_ESCRITA_THEME}}
																onClick={() => handleAbrirTodos()}
															>
																{isOpenDre ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
															</TableCell>
														</TableRow>
														<TableHeader
															isCollapse
															title="Faturamento Bruto Total"
															rows={faturamentoBrutoTotal}
															columns={columns}
															handleOpenModal={handleOpenModal}
															fontWeight="bold"
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															className="linha_1"
															open={open}
															setOpen={setOpen}
															onClick={() => openTourSelected(4)}
															isTour
															zIndexDRE={3}
														>
															<Row
																isCollapse
																title="Faturamento Bruto de Itens"
																rows={faturamentoBrutoItens}
																rowsPercent={faturamentoBrutoItensPercent}
																columns={columns}
																handleOpenModal={handleOpenModal}
																className="linha_2"
																openTour={openTour}
																percentActive={percentActive}
															>
																<Row
																	title="(-) Devoluções e Cancelamentos"
																	subTitle="(Fonte: Sistema Frente de Caixa)"
																	rows={devolucoes}
																	rowsPercent={devolucoesPercent}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	className="linha_3"
																	percentActive={percentActive}
																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Descontos"
																	subTitle="(Fonte: Sistema Frente de Caixa)"
																	rows={descontos}
																	rowsPercent={descontosPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	className="linha_4"
																	percentActive={percentActive}
																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Frete Aplicativos"
																	subTitle="(Fonte: Aplicativos de Delivery)"
																	rows={fretes}
																	rowsPercent={fretesPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	className="linha_5"
																	percentActive={percentActive}
																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Comissões sobre Vendas"
																	rows={comissoes}
																	rowsPercent={comissoesPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	className="linha_6"
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																title="Faturamento Líquido de Itens"
																rows={faturamentoLiquidoItens}
																rowsPercent={faturamentoLiquidoItensPercent}
																columns={columns}
																handleOpenModal={handleOpenModal}
																className="linha_7"
																percentActive={percentActive}
															/>
															<Row
																title="Faturamento de Serviços (Bilheteria e Eventos)"
																rows={faturamentoServicos}
																rowsPercent={faturamentoServicosPercent}
																columns={columns}
																handleOpenModal={handleOpenModal}
																className="linha_8"
																percentActive={percentActive}
															/>
														</TableHeader>
													</TableHead>
													<TableBody>
														<Row
															isCollapse
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															title="Faturamento Líquido Total"
															fontWeight="bold"
															rows={receitasBrutas}
															handleOpenModal={handleOpenModal}
															columns={columns}
															className="linha_9"
															openTour={openTour}
															onClick={() => openTourSelected(12)}
															isTour
														>
															<Row
																isCollapse
																title="(-) Taxas e Impostos sobre Vendas"
																rows={taxasVendas}
																rowsPercent={taxasVendasPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_10"
																openTour={openTour}
																percentActive={percentActive}
															>
																<Row
																	title="(-) Taxas de Cartão e Apps de Delivery"
																	subTitle="(Fonte: Meios de Pagamento)"
																	rows={taxasCartaoAppsDelivery}
																	rowsPercent={taxasCartaoAppsDeliveryPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	className="linha_11"
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
																<RowData
																	rows={rowsReceita}
																	rowsPercent={rowsReceitaPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourReceita}
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																title="Markup"
																rows={markup}
																rowsPercent={markupPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_17"
																percentActive={percentActive}
															/>
															<Row
																isCollapse
																title="(-) CMV - Custo da Mercadoria Vendida"
																rows={cmv}
																rowsPercent={cmvPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_18"
																openTour={openTour}
																percentActive={percentActive}
															>
																<Row
																	backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																	title="% CMV (CMV/Fat. Líquido de Itens)"
																	rows={cmvPorcentagem}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentage={true}
																	className="linha_19"
																/>
																<RowData
																	rows={rowsCVM}
																	rowsPercent={rowsCVMPercent}
																	columns={columns}
																	handleOpenModal={handleOpenModal}
																	tourColumns= {tourCVM}
																	percentActive={percentActive}
																/>

															</Row>
															<Row
																isCollapse
																title="(-) Despesas com Entregas"
																rows={despesasEntregas}
																rowsPercent={despesasEntregasPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_23"
																openTour={openTour}
																percentActive={percentActive}
															>
																<Row
																	title="% Despesas com Entregas (Entregas/Fat. Líquido de Itens)"
																	rows={despesasEntregasPorcent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentage={true}
																	className="linha_24"
																/>
																<Row
																	title="(-) Combustível e Entregas"
																	rows={combustivelEntrega}
																	rowsPercent={combustivelEntregaPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	backgroundColor={temaEscuro ? BLUE_THEME : GRAY_BG_UX}
																	className="linha_25"
																	percentActive={percentActive}
																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Serviços Logísticos"
																	subTitle="(Fonte: Aplicativos de Delivery)"
																	rows={freteParceira}
																	rowsPercent={freteParceiraPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	className="linha_26"
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															title="Custos Variáveis"
															rows={custosVariaveis}
															handleOpenModal={handleOpenModal}
															columns={columns}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_27"
															openTour={openTour}
															onClick={() => openTourSelected(30)}
															isTour
														>
															<Row
																title="% Custos Varíaveis/Fat. Líquido Total"
																percentage={true}
																rows={custosVariaveisPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_28"
															/>
														</Row>
														<Row
															isCollapse
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															title="Margem de contribuição"
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															rows={margemContribuicao}
															handleOpenModal={handleOpenModal}
															columns={columns}
															className="linha_29"
															openTour={openTour}
															onClick={() => openTourSelected(32)}
															isTour
														>
															<Row
																title="% Margem de Contribuição/Fat. Líquido de Itens"
																percentage={true}
																rows={margemContribuicaoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_30"
															/>
														</Row>
														<Row
															isCollapse
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															title="Custos Fixos"
															handleOpenModal={handleOpenModal}
															rows={custosFixos}
															columns={columns}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_31"
															openTour={openTour}
															onClick={() => openTourSelected(34)}
															isTour
														>
															<Row
																title="% Custos Fixos/Receita Bruta"
																percentage={true}
																rows={custosFixosReceitaPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_32"
															/>
															<Row
																isCollapse
																title="(-) Despesas com Pessoal"
																rows={despesasPessoal}
																rowsPercent={despesasPessoalPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_33"
																openTour={openTour}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsDespesasPessoal}
																	rowsPercent={rowsDespesasPessoalPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourDespesasPessoal}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																isCollapse
																title="(-) Despesas com Vendas e Marketing"
																rows={despesasVendasMarketing}
																rowsPercent={despesasVendasMarketingPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_46"
																openTour={openTour}
																percentActive={percentActive}
																color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															>
																<RowData
																	rows={rowsVendasMarketing}
																	rowsPercent={rowsVendasMarketingPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourVendasMarketing}
																	percentActive={percentActive}
																/>
															</Row>

															<Row
																isCollapse
																title="(-) Despesas Operacionais e Administrativas"
																rows={despesasOperacionais}
																rowsPercent={despesasOperacionaisPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_50"
																openTour={openTour}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsOperacionais}
																	rowsPercent={rowsOperacionaisPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourOperacionais}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>

															<Row
																isCollapse
																title="(-) Despesas a Identificar"
																rows={despesasIdentificar}
																rowsPercent={despesasIdentificarPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_63"
																openTour={openTour}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsIdentificar}
																	rowsPercent={rowsIdentificarPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourIdentificar}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															title="Lucro/Prejuízo Operacional"
															rows={lucroPrejuizoOperacional}
															handleOpenModal={handleOpenModal}
															columns={columns}
															className="linha_65"
															openTour={openTour}
															onClick={() => openTourSelected(67)}
															isTour
														>
															<Row
																title="% Lucro ou Prejuízo Operacional/Faturamento Líquido"
																rows={lucroPrejuizoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentage={true}
																className="linha_66"
															/>
															<Row
																title="Ponto de Equilíbrio (Receita Bruta Necessária)"
																rows={pontoEquilibrio}
																rowsPercent={pontoEquilibrioPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_67"
																percentActive={percentActive}
															/>
															<Row
																isCollapse
																openTour={openTour}
																title="(-) Investimentos"
																rows={investimentos}
																rowsPercent={investimentosPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_68"
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsInvestimentos}
																	rowsPercent={rowsInvestimentosPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourInvestimentos}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															openTour={openTour}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															title="Lucro/Prejuízo Líquido"
															rows={lucroPrejuizoLiquido}
															handleOpenModal={handleOpenModal}
															columns={columns}
															className="linha_72"
															onClick={() => openTourSelected(74)}
															isTour
														>
															<Row
																title="% Lucro ou Prejuízo Líquido/Faturamento Líquido"
																percentage={true}
																rows={lucroPrejuizoLiquidoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_73"
															/>
															<Row
																isCollapse
																openTour={openTour}
																title="(-) Empréstimos, Aportes e Retiradas"
																rows={rowsEmprestimosHeader}
																rowsPercent={rowsEmprestimosHeaderPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_74"
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsEmprestimos}
																	rowsPercent={rowsEmprestimosPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourEmprestimos}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																isCollapse
																openTour={openTour}
																title="(-) Reembolso"
																rows={reembolso}
																rowsPercent={reembolsoPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_85"
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsReembolso}
																	rowsPercent={rowsReembolsoPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourReembolso}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															openTour={openTour}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															title="Resultado Líquido de Empréstimos, Aportes e Retiradas"
															rows={rowsLiquidoHeader}
															handleOpenModal={handleOpenModal}
															columns={columns}
															className="linha_79"
															onClick={() => openTourSelected(81)}
															isTour
														>
															<Row
																isCollapse
																openTour={openTour}
																title="(-) Outras Entradas e Saídas"
																rows={transferencia}
																rowsPercent={transferenciaPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_80"
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsTransferencia}
																	rowsPercent={rowsTransferenciaPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	tourColumns= {tourTransferencia}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
													</TableBody>
												</Table>
											) : (
												//estabelecimento
												<Table stickyHeader aria-label="sticky table">
													<TableHead>
														<TableRow>
															{columns.map((column) => (
																<TableCell
																	key={column.label}
																	align={column.align}
																	style={{
																		...{zIndex: 3},
																		...{ fontWeight: "bold" },
																		...{ minWidth: column.minWidth },
																		...{ backgroundColor: temaEscuro && WALPAPPER_THEME},
																		...{color: temaEscuro && LINE_TABLE}
																	}}
																>
																	{column.label}
																</TableCell>
															))}
															<TableCell
																style={{cursor: "pointer", paddingTop: "22px", backgroundColor: temaEscuro && PAPER_PADDING_THEME, color: temaEscuro && WHITE_ESCRITA_THEME}}
																onClick={() => handleAbrirTodos()}
															>
																{isOpenDre ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
															</TableCell>
														</TableRow>
														<TableHeader
															isCollapse
															title="Faturamento Bruto Total"
															rows={faturamentoBrutoTotal}
															columns={columns}
															handleOpenModal={handleOpenModal}
															fontWeight="bold"
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															open={open}
															setOpen={setOpen}
															className="linha_1"
															onClick={() => openTourSelected(3)}
															isTour
															zIndexDRE={3}
														>
															<Row
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																title="(-) Descontos"
																rows={descontos}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_2"
																openTour={openTour}
															/>
														</TableHeader>
													</TableHead>
													<TableBody>
														<Row
															isCollapse
															title="Faturamento Bruto de Itens"
															rows={faturamentoBrutoItens}
															columns={columns}
															handleOpenModal={handleOpenModal}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_3"
															openTour={openTour}
															onClick={() => openTourSelected(5)}
															isTour
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															percentActive={percentActive}
														>
															<Row
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																title="(-) Comissões Sobre Vendas"
																rows={comissoes}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_4"
																percentActive={percentActive}
															/>
															<Row
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																title="Taxas de adquirência e boletos"
																rows={adquirencia}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_5"
																percentActive={percentActive}
															/>
														</Row>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															colorTitle={WHITE_TABLE}
															color={WHITE_TABLE}
															title="Faturamento Líquido de Itens"
															fontWeight="bold"
															rows={faturamentoLiquidoItens}
															handleOpenModal={handleOpenModal}
															columns={columns}
															className="linha_6"
															openTour={openTour}
															onClick={() => openTourSelected(8)}
															isTour
															percentActive={percentActive}
														>
															<Row
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																title="Faturamento Serviços"
																rows={faturamentoServicos}
																handleOpenModal={handleOpenModal}
																columns={columns}
																className="linha_7"
																percentActive={percentActive}
															/>
															<RowData
																rows={rowsReceita}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns= {tourOtherReceita}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
														</Row>
														<Row
															isCollapse
															title="Margem de Contribuição"
															rows={margemContribuicao}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_11"
															open={open}
															setOpen={setOpen}
															onClick={() => openTourSelected(13)}
															isTour
														>
															<Row
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																title="% Margem de Contribuição"
																rows={margemContribuicaoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentage={true}
																className="linha_12"
															/>
														</Row>
														<Row
															isCollapse
															title="Custos Fixos"
															rows={custosFixos}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_13"
															openTour={openTour}
															onClick={() => openTourSelected(15)}
															isTour
														>
															<Row
																title="% Custos Fixos"
																rows={custosFixosReceitaPorcentagem}
																columns={columns}
																handleOpenModal={handleOpenModal}
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																percentage={true}
																className="linha_14"
															/>
														</Row>
														<Row
															isCollapse
															title="Despesas com Pessoal"
															rows={despesasPessoal}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_15"
															openTour={openTour}
															onClick={() => openTourSelected(17)}
															isTour
														>
															<RowData
																rows={rowsDespesasPessoal}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns={tourOtherDespesasPessoal}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
														</Row>
														<Row
															isCollapse
															title="Marketing e Endomarketing"
															rows={despesasVendasMarketing}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_26"
															openTour={openTour}
															onClick={() => openTourSelected(28)}
															isTour
														>
															<RowData
																rows={rowsVendasMarketing}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns={tourOtherVendasMarketing}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
														</Row>
														<Row
															isCollapse
															title="Despesas Operacionais e Administrativas"
															rows={despesasOperacionais}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_30"
															openTour={openTour}
															onClick={() => openTourSelected(32)}
															isTour
														>
															<RowData
																rows={rowsOperacionais}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns= {tourOtherOperacionais}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
															<Row
																title="(-) Despesas a Identificar"
																rows={despesasIdentificar}
																columns={columns}
																handleOpenModal={handleOpenModal}
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																className="linha_40"
																openTour={openTour}
															/>
															<RowData
																rows={rowsIdentificar}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns= {tourOtherIdentificar}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
														</Row>
														<Row
															isCollapse
															title="Lucro Operacional"
															rows={lucroPrejuizoOperacional}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_42"
															openTour={openTour}
															onClick={() => openTourSelected(44)}
															isTour
														>
															<Row
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																title="% Lucro/Prejuízo Operacional"
																rows={lucroPrejuizoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentage={true}
																className="linha_43"
															/>
															<Row
																title="Ponto de Equilíbrio"
																rows={pontoEquilibrio}
																columns={columns}
																handleOpenModal={handleOpenModal}
																backgroundColor={temaEscuro ? ITENS_PAINEL : BLUE_LIGHTER_UX}
																className="linha_44"
															/>
														</Row>
														<Row
															isCollapse
															title="Investimentos"
															rows={investimentos}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_45"
															openTour={openTour}
															onClick={() => openTourSelected(47)}
															isTour
														>
															<RowData
																rows={rowsInvestimentos}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns={tourOtherInvestimentos}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
														</Row>
														<Row
															isCollapse
															title="Lucro/Prejuízo Líquido"
															rows={lucroPrejuizoLiquido}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_47"
															openTour={openTour}
															onClick={() => openTourSelected(49)}
															isTour
														>
															<RowData
																rows={rowsEmprestimos}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns={tourOtherEmprestimos}
															/>
														</Row>
														<Row
															isCollapse
															title="Resultado Líquido de Empréstimos, Aportes e Retiradas"
															rows={rowsLiquidoHeader}
															handleOpenModal={handleOpenModal}
															columns={columns}
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															fontWeight="bold"
															className="linha_52"
															openTour={openTour}
															onClick={() => openTourSelected(54)}
															isTour
														>
															<RowData
																rows={rowsTransferencia}
																handleOpenModal={handleOpenModal}
																columns={columns}
																tourColumns={tourOtherTransferencia}
																backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
															/>
														</Row>
													</TableBody>
												</Table>
											)}
										</TableContainer>
									</Grid>
								</Grid>
							</React.Fragment>
						)
				)
				}
			</Paper>
		</div>
	);
};

const styles = {
    iconSize: {
        fontSize: "20px",
    },
};

export default DRE;
