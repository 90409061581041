import React, {useState, useRef, useCallback, useMemo} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
	BLACK_TABLE,
	GRAY_TABLE,
	GRAY_TEXT_TABLE,
	GRAY_WEAK_TABLE,
	RED_TABLE,
	WHITE_TABLE,
	YELLOW_TABLE,
	YELLOW_WEAK_TABLE,
	YELLOW_INFO_UX,
	styleDefaultText,
	BLUE_INFO_UX,
	PURPLE_INFO_UX,
	BLUE_LIGHTER_UX,
	GRAY_LABEL_UX,
	paperStyle,
	WALPAPPER_THEME,
	LINE_TABLE,
	BLUE_THEME,
	ROW_MAIN,
	GRAY_BG_UX,
	PAPER_PADDING_THEME, WHITE_THEME_BLACK,
	stylesRow
} from "../../shared/utils";
import Row from "../../components/Table/Row";
import RowData from "../../components/Table/RowData";
import api from "../../axios";
import {useSelector} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import SelectConsolidado from "../../components/SelectStore/SelectConsolidado";
import FilterLojas from "../../components/Filter/FilterLojas";
import { useReactToPrint } from "react-to-print";
import GrayButton from "../../components/Buttons/GrayButton";
import CircleIcon from "@mui/icons-material/Circle";
import Header from "../../components/Titles/Header";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {showSnackMessage} from "../../actions/SnackActions";
import {useDispatch} from "react-redux";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import {changeOpenComponent} from "../../actions/StoreActions";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import IconButton from "@mui/material/IconButton";
import Tour from "reactour";
import { DASH_BAR_COLOR, GRID_BUTTONS_SPACING, convertLabelToNumber } from "../../shared/utils";
import { steps } from "./TourDREConsolidada";
import GenericModal from "../../components/Modal/GenericModal";
import {
	dreConsolidadaNotDetalharList,
	dreConsolidadaDetalharListIfood
} from "./DREConsolidadaEnums";
import TableHeader from "../../components/Table/TableHeader";
import OptionsMenuComponent from "../../components/Buttons/OptionsMenuComponent";


const DREConsolidada = () => {
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState(false);
	const [selectedField, setSelectedField] = useState("");
	const [loading, setLoading] = useState(false);
	const [faturamentoBrutoTotal, setFaturamentoBrutoTotal] = useState([]);
	const [faturamentoBrutoItens, setFaturamentoBrutoItens] = useState([]);
	const [faturamentoLiquidoItens, setFaturamentoLiquidoItens] = useState([]);
	const [faturamentoServicos, setFaturamentoServicos] = useState([]);
	const [devolucoes, setDevolucoes] = useState([]);
	const [descontos, setDescontos] = useState([]);
	const [freteParceira, setFreteParceira] = useState([]);
	const [fretes, setFretes] = useState([]);
	const [comissoes, setComissoes] = useState([]);
	const [receitasBrutas, setReceitasBrutas] = useState([]);
	const [taxasCartaoAppsDelivery, setTaxasCartaoAppsDelivery] = useState([]);
	const [taxasVendas, setTaxasVendas] = useState([]);
	const [rowsReceita, setRowsReceita] = useState([]);
	const [markup, setMarkup] = useState([]);
	const [cmvPorcentagem, setCmvPorcentagem] = useState([]);
	const [cmv, setCmv] = useState([]);
	const [rowsCVM, setRowsCVM] = useState([]);
	const [custosVariaveis, setCustosVariaveis] = useState([]);
	const [custosVariaveisPorcentagem, setCustosVariaveisPorcentagem] = useState([]);
	const [margemContribuicao, setMargemContribuicao] = useState([]);
	const [margemContribuicaoPorcentagem, setMargemContribuicaoPorcentagem] = useState([]);
	const [custosFixosReceitaPorcentagem, setCustosFixosReceitaPorcentagem] = useState([]);
	const [custosFixos, setCustosFixos] = useState([]);
	const [despesasPessoal, setDespesasPessoal] = useState([]);
	const [rowsDespesasPessoal, setRowsDespesasPessoal] = useState([]);
	const [despesasVendasMarketing, setDespesasVendasMarketing] = useState([]);
	const [rowsVendasMarketing, setRowsVendasMarketing] = useState([]);
	const [despesasOperacionais, setDespesasOperacionais] = useState([]);
	const [rowsOperacionais, setRowsOperacionais] = useState([]);
	const [despesasIdentificar, setDespesasIdentificar] = useState([]);
	const [rowsIdentificar, setRowsIdentificar] = useState([]);
	const [lucroPrejuizoPorcentagem, setLucroPrejuizoPorcentagem] = useState([]);
	const [lucroPrejuizoOperacional, setLucroPrejuizoOperacional] = useState([]);
	const [pontoEquilibrio, setPontoEquilibrio] = useState([]);
	const [investimentos, setInvestimentos] = useState([]);
	const [rowsInvestimentos, setRowsInvestimentos] = useState([]);
	const [lucroPrejuizoLiquido, setLucroPrejuizoLiquido] = useState([]);
	const [lucroPrejuizoLiquidoPorcentagem, setLucroPrejuizoLiquidoPorcentagem] = useState([]);
	const [rowsEmprestimosHeader, setRowsEmprestimosHeader] = useState([]);
	const [rowsEmprestimos, setRowsEmprestimos] = useState([]);
	const [rowsLiquidoHeader, setRowsLiquidoHeader] = useState([]);
	const [transferencia, setTransferencia] = useState([]);
	const [rowsTransferencia, setRowsTransferencia] = useState([]);
	const [custoEntrega, setCustoEntrega] = useState([]);
	const [custoEntregaPctg, setCustoEntregaPctg] = useState([]);
	const [vendasDelivery, setVendasDelivery] = useState([]);
	const [vendasDeliveryPctg, setVendasDeliveryPctg] = useState([]);
	const [vendasSalao, setVendasSalao] = useState([]);
	const [vendasSalaoPctg, setVendasSalaoPctg] = useState([]);
	const [despesasEntregas, setDespesasEntregas] = useState([]);
	const [despesasEntregasPorcent, setDespesasEntregasPorcent] = useState([]);
	const [combustivelEntrega, setCombustivelEntrega] = useState([]);
	const [reembolso, setReembolso] = useState([]);
	const [reembolsoPercent, setReembolsoPercent] = useState([]);
	const [rowsReembolso, setRowsReembolso] = useState([]);
	const [rowsReembolsoPercent, setRowsReembolsoPercent] = useState([]);
	const [columns, setColumns] = useState([]);
	const [lojasSelected, setLojasSelected] = useState([]);
	const isAdmin = useSelector(state => state.AuthReducer.isAdmin);
	const temaEscuro = useSelector(state => state.AuthReducer.temaEscuro);
	const filteredSteps = isAdmin ? steps : steps.filter(step => step.selector !== "[data-testid='refresh-btn']");
	const [openModalFilter, setOpenModalFilter] = useState(false);
	const [monthOptions, setMonthOptions] = useState([]);
	const [previousMonths, setPreviousMonths] = useState([]);
	const [extraCalc] = useState(false);
	const [monthsSelected, setMonthsSelected] = useState([]);
	const [open, setOpen] =  useState(false);
	const [startAt, setStartAt] = useState(0);
	const [selectedLabel, setSelectedLabel] = useState({});
	const [monthsDisplay, setMonthsDisplay] = useState(false);
	const [filteredMonths, setFilteredMonths] = useState([]);
	const [openHeader, setOpenHeader] = useState(false);
	const [canCallGetMeses, setCanCallGetMeses] = useState(true);
	const [percentActive, setPercentActive] = useState(false);

	const [faturamentoBrutoItensPercent, setFaturamentoBrutoItensPercent] = useState([]);
	const [faturamentoLiquidoItensPercent, setFaturamentoLiquidoItensPercent] = useState([]);
	const [faturamentoServicosPercent, setFaturamentoServicosPercent] = useState([]);
	const [devolucoesPercent, setDevolucoesPercent] = useState([]);
	const [descontosPercent, setDescontosPercent] = useState([]);
	const [freteParceiraPercent, setFreteParceiraPercent] = useState([]);
	const [fretesPercent, setFretesPercent] = useState([]);
	const [comissoesPercent, setComissoesPercent] = useState([]);
	const [taxasCartaoAppsDeliveryPercent, setTaxasCartaoAppsDeliveryPercent] = useState([]);
	const [taxasVendasPercent, setTaxasVendasPercent] = useState([]);
	const [rowsReceitaPercent, setRowsReceitaPercent] = useState([]);
	const [markupPercent, setMarkupPercent] = useState([]);
	const [cmvPercent, setCmvPercent] = useState([]);
	const [rowsCVMPercent, setRowsCVMPercent] = useState([]);
	const [despesasPessoalPercent, setDespesasPessoalPercent] = useState([]);
	const [rowsDespesasPessoalPercent, setRowsDespesasPessoalPercent] = useState([]);
	const [despesasVendasMarketingPercent, setDespesasVendasMarketingPercent] = useState([]);
	const [rowsVendasMarketingPercent, setRowsVendasMarketingPercent] = useState([]);
	const [despesasOperacionaisPercent, setDespesasOperacionaisPercent] = useState([]);
	const [rowsOperacionaisPercent, setRowsOperacionaisPercent] = useState([]);
	const [despesasIdentificarPercent, setDespesasIdentificarPercent] = useState([]);
	const [rowsIdentificarPercent, setRowsIdentificarPercent] = useState([]);
	const [pontoEquilibrioPercent, setPontoEquilibrioPercent] = useState([]);
	const [investimentosPercent, setInvestimentosPercent] = useState([]);
	const [rowsInvestimentosPercent, setRowsInvestimentosPercent] = useState([]);
	const [rowsEmprestimosHeaderPercent, setRowsEmprestimosHeaderPercent] = useState([]);
	const [rowsEmprestimosPercent, setRowsEmprestimosPercent] = useState([]);
	const [transferenciaPercent, setTransferenciaPercent] = useState([]);
	const [rowsTransferenciaPercent, setRowsTransferenciaPercent] = useState([]);
	const [despesasEntregasPercent, setDespesasEntregasPercent] = useState([]);
	const [combustivelEntregaPercent, setCombustivelEntregaPercent] = useState([]);


	const isOpenDre = useSelector(state => state.StoreReducer.openComponent);

	const handleClickAway = (lojas) => {
		if (lojas.length) {

			setMonthsDisplay(true);
			
			if (canCallGetMeses) {
				const dataRequest = {
					lojas
				};
	
				api.GetMesesCompetencia(dataRequest).then((response) => {
	
					let dataResponse = response.data;
					
					setFilteredMonths(dataResponse);
	
					setCanCallGetMeses(false);
				});
			}
		}
	};

	const handleFilterLojas = useCallback((months, lojas) => {
		if (months.length > 0 && lojas.length > 0){
			setLoading(true);
			setLojasSelected(lojas);
			setMonthsSelected(months);
			getDREConsolidada(months, lojas);
			setCanCallGetMeses(true);
		} else {
			setLoading(false);
			dispatch(showSnackMessage({message: "Selecione pelo menos uma loja e um mês", severity: "error"}));
		}
	}, []);

	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleRefresh = useCallback(() => {
		if (monthsSelected.length > 0 && lojasSelected.length > 0){
			setLoading(true);
			const dataRequest = {
				lojas: lojasSelected,
				months: monthsSelected
			};
			api.RefreshDREConsolidada(dataRequest).then(() => {
				setLoading(false);
				getDREConsolidada(monthsSelected, lojasSelected);
			}).catch(() => {
				setLoading(false);
			});
		}
	}, [monthsSelected, lojasSelected]);

	const getDREConsolidada = (months=[], lojas=[]) => {
		setLoading(true);
		const dataRequest = {
			lojas,
			style: {
				BLACK_TABLE,
				GRAY_TABLE,
				GRAY_TEXT_TABLE,
				GRAY_WEAK_TABLE,
				RED_TABLE,
				WHITE_TABLE,
				YELLOW_TABLE,
				YELLOW_WEAK_TABLE,
				BLUE_LIGHTER_UX
			},
			months
		};
		api.GetDREConsolidada(dataRequest).then(response => {
			setLoading(false);
			let dataResponse = response.data;
			let dre = dataResponse.dre;
			let drePercentage = dataResponse.dre_percentage;

			setPreviousMonths(dataResponse.filtered_months);
			setColumns(dataResponse.columns);
			setFaturamentoBrutoTotal(dre.dre_faturamento_total.values);
			setFaturamentoBrutoItens(dre.dre_faturamento_itens.values);
			setDevolucoes(dre.dre_devolucoes.values);
			setDescontos(dre.dre_descontos.values);
			setFreteParceira(dre.dre_valor_frete_parceira.values);
			setFretes(dre.dre_fretes.values);
			setComissoes(dre.dre_comissoes.values);
			setFaturamentoLiquidoItens(dre.dre_faturamento_liquido_itens.values);
			setFaturamentoServicos(dre.dre_faturamento_liquido_servicos.values);
			setDespesasEntregas(dre.dre_despesas_entregas.values);
			setDespesasEntregasPorcent(dre.dre_despesas_entregas_porcentagem.values);
			setCombustivelEntrega(dre.dre_combustivel_entregas.values);
			setReceitasBrutas(dre.dre_faturamento_liquido_total.values);
			setTaxasCartaoAppsDelivery(dre.dre_taxas_cartoes_apps_delivery.values);
			setTaxasVendas(dre.dre_taxa_vendas.values);
			setRowsReceita(dre.dre_taxa_vendas.data);
			setMarkup(dre.dre_markup.values);
			setCmvPorcentagem(dre.dre_cmv_porcentagem.values);
			setCmv(dre.dre_cmv.values);
			setRowsCVM(dre.dre_cmv.data);
			setCustosVariaveis(dre.dre_custos_variaveis.values);
			setCustosVariaveisPorcentagem(dre.dre_custos_variaveis_porcentagem.values);
			setMargemContribuicao(dre.dre_margem_contribuicao.values);
			setMargemContribuicaoPorcentagem(dre.dre_margem_contribuicao_porcentagem.values);
			setCustosFixosReceitaPorcentagem(dre.dre_custos_fixos_receita_porcentagem.values);
			setCustosFixos(dre.dre_custos_fixos.values);
			setDespesasPessoal(dre.dre_despesas_com_pessoal.values);
			setRowsDespesasPessoal(dre.dre_despesas_com_pessoal.data);
			setDespesasVendasMarketing(dre.dre_despesas_vendas_marketing.values);
			setRowsVendasMarketing(dre.dre_despesas_vendas_marketing.data);
			setDespesasOperacionais(dre.dre_despesas_op_admin.values);
			setRowsOperacionais(dre.dre_despesas_op_admin.data);
			setDespesasIdentificar(dre.dre_despesas_identificar.values);
			setRowsIdentificar(dre.dre_despesas_identificar.data);
			setLucroPrejuizoPorcentagem(dre.dre_lucro_prejuizo_operacional_porcentagem.values);
			setLucroPrejuizoOperacional(dre.dre_lucro_prejuizo_operacional.values);
			setPontoEquilibrio(dre.dre_ponto_equilibrio.values);
			setInvestimentos(dre.dre_investimentos.values);
			setRowsInvestimentos(dre.dre_investimentos.data);
			setLucroPrejuizoLiquido(dre.dre_lucro_prejuizo_liquido.values);
			setLucroPrejuizoLiquidoPorcentagem(dre.dre_lucro_prejuizo_liquido_porcentagem.values);
			setRowsEmprestimosHeader(dre.dre_emprestimos.values);
			setRowsEmprestimos(dre.dre_emprestimos.data);
			setRowsLiquidoHeader(dre.dre_resultado_liquido.values);
			setTransferencia(dre.dre_transferencias.values);
			setRowsTransferencia(dre.dre_transferencias.data);
			setCustoEntrega(dre.dre_custo_entrega_reais.values);
			setCustoEntregaPctg(dre.dre_custo_entrega_porcentagem.values);
			setVendasDelivery(dre.dre_vendas_delivery_reais.values);
			setVendasDeliveryPctg(dre.dre_vendas_delivery_porcentagem.values);
			setVendasSalao(dre.dre_vendas_salao_reais.values);
			setVendasSalaoPctg(dre.dre_vendas_salao_porcentagem.values);
			setVendasSalaoPctg(dre.dre_vendas_salao_porcentagem.values);
			setReembolso(dre.dre_reembolso_ifood.values);
			setReembolsoPercent(drePercentage.dre_reembolso_ifood.values);
			setRowsReembolso(dre.dre_reembolso_ifood.data);
			setRowsReembolsoPercent(drePercentage.dre_reembolso_ifood.data);

			setFaturamentoBrutoItensPercent(drePercentage.dre_faturamento_itens.values);
			setDevolucoesPercent(drePercentage.dre_devolucoes.values);
			setDescontosPercent(drePercentage.dre_descontos.values);
			setFreteParceiraPercent(drePercentage.dre_valor_frete_parceira.values);
			setFretesPercent(drePercentage.dre_fretes.values);
			setComissoesPercent(drePercentage.dre_comissoes.values);
			setFaturamentoLiquidoItensPercent(drePercentage.dre_faturamento_liquido_itens.values);
			setFaturamentoServicosPercent(drePercentage.dre_faturamento_liquido_servicos.values);
			setDespesasEntregasPercent(drePercentage.dre_despesas_entregas.values);
			setCombustivelEntregaPercent(drePercentage.dre_combustivel_entregas.values);
			setTaxasCartaoAppsDeliveryPercent(drePercentage.dre_taxas_cartoes_apps_delivery.values);
			setTaxasVendasPercent(drePercentage.dre_taxa_vendas.values);
			setRowsReceitaPercent(drePercentage.dre_taxa_vendas.data);
			setMarkupPercent(drePercentage.dre_markup.values);
			setCmvPercent(drePercentage.dre_cmv.values);
			setRowsCVMPercent(drePercentage.dre_cmv.data);
			setDespesasPessoalPercent(drePercentage.dre_despesas_com_pessoal.values);
			setRowsDespesasPessoalPercent(drePercentage.dre_despesas_com_pessoal.data);
			setDespesasVendasMarketingPercent(drePercentage.dre_despesas_vendas_marketing.values);
			setRowsVendasMarketingPercent(drePercentage.dre_despesas_vendas_marketing.data);
			setDespesasOperacionaisPercent(drePercentage.dre_despesas_op_admin.values);
			setRowsOperacionaisPercent(drePercentage.dre_despesas_op_admin.data);
			setDespesasIdentificarPercent(drePercentage.dre_despesas_identificar.values);
			setRowsIdentificarPercent(drePercentage.dre_despesas_identificar.data);
			setPontoEquilibrioPercent(drePercentage.dre_ponto_equilibrio.values);
			setInvestimentosPercent(drePercentage.dre_investimentos.values);
			setRowsInvestimentosPercent(drePercentage.dre_investimentos.data);
			setRowsEmprestimosHeaderPercent(drePercentage.dre_emprestimos.values);
			setRowsEmprestimosPercent(drePercentage.dre_emprestimos.data);
			setTransferenciaPercent(drePercentage.dre_transferencias.values);
			setRowsTransferenciaPercent(drePercentage.dre_transferencias.data);

			setOpenModalFilter(false);
			setMonthsDisplay(false);
		}).catch(() => {
			setLoading(false);
			setOpenModalFilter(false);
			setColumns([]);
		});
	};

	const emptyFunction = useCallback(() => null, []);

	const emptyArray = [];

	const fileIcon = useMemo(() => (
		<FileDownloadIcon/>
	), []);

	const handleAbrirTodos = () => {
		dispatch(changeOpenComponent(!isOpenDre));
	};

	const changeOpen = () => {
		setStartAt(0); 
		setOpen(!open);
	};

	const handleDetalharValor = useCallback((columnId=selectedLabel, fieldId=selectedField) => {
		if (dreConsolidadaNotDetalharList.includes(fieldId)){
			dispatch(showSnackMessage({message: "Impossivel detalhar categoria.", severity: "warning"}));
		} else {
				const baseUrl = "/detalha_";
				const urlSuffix = dreConsolidadaDetalharListIfood.includes(fieldId) ? "ifood" : "ca";
				const url = `${baseUrl}${urlSuffix}`;
				const params = { lojas: lojasSelected, columnId, fieldId, table: "DRE"};
				const queryString = new URLSearchParams(params).toString();
				const fullUrl = `${url}?${queryString}`;
				window.open(fullUrl, "_blank");
			}
		}, [lojasSelected, selectedLabel, selectedField]);

	const handleOpenModal = useCallback( (data, index, field) => {
		let label = columns[index + 1].label;
		let newLabel = convertLabelToNumber(label);
		setSelectedField(field);
		setSelectedLabel(newLabel);
		if (isAdmin){
			setOpenModal(true);
		} else {
			handleDetalharValor(newLabel, field);
		}
	}, [isAdmin, columns]);

	const changeStatePercent = () => {
		setPercentActive(!percentActive);
	};

	return (
		<div className="main">
			<Header title="DRE - Demonstração do Resultado do Exercício Consolidada"/>
			{
				!!isAdmin && (
					<GenericModal
						isConsolidada
						openModal={openModal}
						setOpenModal={setOpenModal}
						fixedValue={0}
						setFixedValue={emptyFunction}
						setOpenModalFixedValue={emptyFunction}
						openModalFixedValue={false}
						openViewCommentModal={false}
						openInsertCommentModal={false}
						setOpenViewCommentModal={emptyFunction}
						setOpenInsertCommentModal={emptyFunction}
						commentsList={emptyArray}
						comment={""}
						setComment={emptyFunction}
						editComment={emptyFunction}
						loadingModal={false}
						delMetadata={emptyFunction}
						sendMetadata={emptyFunction}
						isEdit={false}
						setIsEdit={emptyFunction}
						handleDetalharValor={handleDetalharValor}
						selectedField={selectedField}
						component="dre"
					/>
				)
			}
			<Paper sx={{...paperStyle, backgroundColor: temaEscuro ? PAPER_PADDING_THEME : GRAY_BG_UX, backgroundImage: temaEscuro && "none"}}>
				<Grid container sx={{display: "flex", alignItems: "center", pb: 2}}>
					<Grid item xs={6}>
						<GrayButton
							testId="btn-filtrar"
							onClick={() => setOpenModalFilter(true)}
							title="Filtrar"
							startIcon={<FilterListOutlinedIcon/>}
						/>
					</Grid>
					{lojasSelected?.length >= 1 && (
						<Grid item xs={6}>
							<Grid container spacing={GRID_BUTTONS_SPACING} sx={{display: "flex", justifyContent: "end"}}>
								<Grid item>
									<OptionsMenuComponent
										isAdmin={isAdmin}
										handleRefresh={handleRefresh}
										monthsNotRevisados={emptyArray}
										setMonthOptions={emptyFunction}
										optionFilter
										disabled={false}
										changeStatePercent={changeStatePercent}
										className="button_options"
										component="Consolidada"
									/>
								</Grid>
								<Grid item>
									<GrayButton
										title="Exportar"
										startIcon={fileIcon}
										onClick={handlePrint}
										className="exportar"
									/>
								</Grid>
								<Grid item>
									<IconButton style={{background: "#ECC94B", borderRadius: "4px"}} onClick={() => changeOpen()}>
										<LightbulbIcon style={{color: GRAY_LABEL_UX, fontSize: "20px", padding: "1px"}}/>
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid item xs={6}>
						<Tour
							steps={filteredSteps}
							isOpen={open}
							onRequestClose={changeOpen}
							accentColor={DASH_BAR_COLOR}
							startAt={startAt}
							rounded={5}
							showNavigation={false}
							inViewThreshold={60}
							className={temaEscuro && "tour"}
							badgeContent={(curr, tot) => `${curr}/${tot}`}
						/>
					</Grid>
					<Grid item>
						<FilterLojas
							openModalFilter={openModalFilter}
							setOpenModalFilter={setOpenModalFilter}
							handleFilterLojas={handleFilterLojas}
							previousMonths={previousMonths}
							monthOptions={monthOptions}
							setMonthOptions={setMonthOptions}
							handleClickAway={handleClickAway}
							monthsDisplay={monthsDisplay}
							filteredMonths={filteredMonths}
							setCanCallGetMeses={setCanCallGetMeses}
						/>
					</Grid>
				</Grid>

					{loading ? (
						[...Array(15).keys()].map((i,d) =>{
							return(
								<Skeleton key={d} height={50}/>
							);
						})
					) : (
						lojasSelected?.length < 1?
							(
								<SelectConsolidado/>
							) : (
								<React.Fragment>
									<Grid container spacing={2} data-testid="dre-consolidada">
										<Grid item xs={12}>

										</Grid>
										<Grid item xs={8}>
											<Grid spacing={2} container>
												<Grid item>
													<Grid container>
														<Grid item sx={{display: "flex", alignItems: "center"}}>
															<CircleIcon className="circles" style={{ color: YELLOW_INFO_UX, }}/>
														</Grid>
														<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
															<a>Valor Manual</a>
														</Grid>
													</Grid>
												</Grid>
												<Grid item>
													<Grid container>
														<Grid item sx={{display: "flex", alignItems: "center"}}>
															<CircleIcon className="circles" style={{ color: BLUE_INFO_UX, }}/>
														</Grid>
														<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
															<a>Provisão</a>
														</Grid>
													</Grid>
												</Grid>
												<Grid item>
													<Grid container>
														<Grid item sx={{display: "flex", alignItems: "center"}}>
															<CircleIcon className="circles" style={{ color: PURPLE_INFO_UX, }}/>
														</Grid>
														<Grid item sx={{...styleDefaultText, display: "flex", alignItems: "center", color: temaEscuro ? WHITE_THEME_BLACK : GRAY_LABEL_UX}}>
															<a>Comentário</a>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<TableContainer ref={componentRef} className="table-container">
												<Table stickyHeader aria-label="sticky table">
													<TableHead>
														<TableRow>
															{columns.map((column) => (
																<TableCell
																	key={column.label}
																	align={column.align}
																	style={{
																		...{zIndex: 3},
																		...{fontWeight: "bold", },
																		...{ minWidth: column.minWidth },
																		...{ backgroundColor: temaEscuro && WALPAPPER_THEME},
																		...{color: temaEscuro && LINE_TABLE}}}
																>
																	{column.label}
																</TableCell>
															))}
															<TableCell
																style={{paddingTop: "22px", backgroundColor: temaEscuro && WALPAPPER_THEME, ...stylesRow}}
																onClick={() => handleAbrirTodos()}
															>
																{isOpenDre ? <KeyboardDoubleArrowUpIcon/> : <KeyboardDoubleArrowDownIcon/>}
															</TableCell>
														</TableRow>
														<TableHeader
															isCollapse
															title="Faturamento Bruto Total"
															rows={faturamentoBrutoTotal}
															columns={columns}
															handleOpenModal={handleOpenModal}
															fontWeight="bold"
															backgroundColor={temaEscuro ? ROW_MAIN : GRAY_LABEL_UX}
															colorTitle={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															color={temaEscuro ? LINE_TABLE : WHITE_TABLE}
															zIndexDRE={3}
															setOpen={setOpenHeader}
															open={openHeader}
														>
															<Row
																isCollapse
																title="Faturamento Bruto de Itens"
																rows={faturamentoBrutoItens}
																rowsPercent={faturamentoBrutoItensPercent}
																columns={columns}
																handleOpenModal={handleOpenModal}
																percentActive={percentActive}
															>
																<Row
																	title="(-) Devoluções e Cancelamentos"
																	subTitle="(Fonte: Sistema Frente de Caixa)"
																	rows={devolucoes}
																	rowsPercent={devolucoesPercent}
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}

																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Descontos"
																	subTitle="(Fonte: Sistema Frente de Caixa)"
																	rows={descontos}
																	rowsPercent={descontosPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}

																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Frete Aplicativos"
																	subTitle="(Fonte: Aplicativos de Delivery)"
																	rows={fretes}
																	rowsPercent={fretesPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Comissões sobre Vendas"
																	rows={comissoes}
																	rowsPercent={comissoesPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																title="Faturamento Líquido de Itens"
																rows={faturamentoLiquidoItens}
																rowsPercent={faturamentoLiquidoItensPercent}
																columns={columns}
																handleOpenModal={handleOpenModal}
																percentActive={percentActive}
															/>
															<Row
																title="Faturamento de Serviços (Bilheteria e Eventos)"
																rows={faturamentoServicos}
																rowsPercent={faturamentoServicosPercent}
																columns={columns}
																handleOpenModal={handleOpenModal}
																percentActive={percentActive}
															/>
														</TableHeader>
													</TableHead>
													<TableBody>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															colorTitle={WHITE_TABLE}
															color={WHITE_TABLE}
															title="Faturamento Líquido Total"
															fontWeight="bold"
															rows={receitasBrutas}
															handleOpenModal={handleOpenModal}
															columns={columns}
														>
															<Row
																isCollapse
																title="(-) Taxas e Impostos sobre Vendas"
																rows={taxasVendas}
																rowsPercent={taxasVendasPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<Row
																	title="(-) Taxas de Cartão e Apps de Delivery"
																	subTitle="(Fonte: Meios de Pagamento)"
																	rows={taxasCartaoAppsDelivery}
																	rowsPercent={taxasCartaoAppsDeliveryPercent}
																	backgroundColor={temaEscuro && BLUE_THEME}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
																<RowData
																	rows={rowsReceita}
																	rowsPercent={rowsReceitaPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>


															<Row
																title="Markup"
																rows={markup}
																rowsPercent={markupPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															/>
															<Row
																isCollapse
																title="(-) CMV - Custo da Mercadoria Vendida"
																rows={cmv}
																rowsPercent={cmvPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<Row
																	title="% CMV (CMV/Fat. Líquido de Itens)"
																	rows={cmvPorcentagem}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentage={true}
																/>
																<RowData
																	rows={rowsCVM}
																	rowsPercent={rowsCVMPercent}
																	columns={columns}
																	handleOpenModal={handleOpenModal}
																	percentActive={percentActive}
																/>
															</Row>

															<Row
																isCollapse
																title="(-) Despesas com entregas"
																rows={despesasEntregas}
																rowsPercent={despesasEntregasPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<Row
																	title="% Despesas com entregas (Entregas/Fat. Líquido de Itens)"
																	rows={despesasEntregasPorcent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentage={true}
																/>
																<Row
																	title="(-) Combustível e Entregas"
																	rows={combustivelEntrega}
																	rowsPercent={combustivelEntregaPercent}
																	handleOpenModal={handleOpenModal}
																	backgroundColor={temaEscuro ? BLUE_THEME: BLUE_LIGHTER_UX}
																	columns={columns}
																	percentActive={percentActive}
																/>
																<Row
																	backgroundColor={temaEscuro ? BLUE_THEME : WHITE_TABLE}
																	title="(-) Serviços Logísticos"
																	subTitle="(Fonte: Aplicativos de Delivery)"
																	rows={freteParceira}
																	rowsPercent={freteParceiraPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															title="Custos Variáveis"
															rows={custosVariaveis}
															handleOpenModal={handleOpenModal}
															columns={columns}
															colorTitle={WHITE_TABLE}
															color={WHITE_TABLE}
															fontWeight="bold"
														>
															<Row
																title="% Custos Varíaveis/Fat. Líquido Total"
																percentage={true}
																rows={custosVariaveisPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
															/>
														</Row>


														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															title="Margem de Contribuição"
															color={WHITE_TABLE}
															colorTitle={WHITE_TABLE}
															fontWeight="bold"
															rows={margemContribuicao}
															handleOpenModal={handleOpenModal}
															columns={columns}
														>
															<Row
																title="% Margem de Contribuição/Fat. Líquido de Itens"
																percentage={true}
																rows={margemContribuicaoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
															/>
														</Row>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															title="Custos Fixos"
															handleOpenModal={handleOpenModal}
															rows={custosFixos}
															columns={columns}
															color={WHITE_TABLE}
															colorTitle={WHITE_TABLE}
															fontWeight="bold"
														>
															<Row
																title="% Custos Fixos/Receita Bruta"
																percentage={true}
																rows={custosFixosReceitaPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
															/>
															<Row
																isCollapse
																title="(-) Despesas com Pessoal"
																rows={despesasPessoal}
																rowsPercent={despesasPessoalPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsDespesasPessoal}
																	rowsPercent={rowsDespesasPessoalPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>

															<Row
																isCollapse
																title="(-) Despesas com Vendas e Marketing"
																rows={despesasVendasMarketing}
																rowsPercent={despesasVendasMarketingPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsVendasMarketing}
																	rowsPercent={rowsVendasMarketingPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>

															<Row
																isCollapse
																title="(-) Despesas Operacionais e Administrativas"
																rows={despesasOperacionais}
																rowsPercent={despesasOperacionaisPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsOperacionais}
																	rowsPercent={rowsOperacionaisPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																isCollapse
																title="(-) Despesas a Identificar"
																rows={despesasIdentificar}
																rowsPercent={despesasIdentificarPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsIdentificar}
																	rowsPercent={rowsIdentificarPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															color={WHITE_TABLE}
															colorTitle={WHITE_TABLE}
															fontWeight="bold"
															title="Lucro/Prejuízo Operacional"
															rows={lucroPrejuizoOperacional}
															handleOpenModal={handleOpenModal}
															columns={columns}
														>
															<Row
																title="% Lucro ou Prejuízo Operacional/Faturamento Líquido"
																rows={lucroPrejuizoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentage={true}
															/>
															<Row
																title="Ponto de Equilíbrio (Receita Bruta Necessária)"
																rows={pontoEquilibrio}
																rowsPercent={pontoEquilibrioPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															/>
															<Row
																isCollapse
																title="(-) Investimentos"
																rows={investimentos}
																rowsPercent={investimentosPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsInvestimentos}
																	rowsPercent={rowsInvestimentosPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>

														</Row>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															color={WHITE_TABLE}
															colorTitle={WHITE_TABLE}
															fontWeight="bold"
															title="Lucro/Prejuízo Líquido"
															rows={lucroPrejuizoLiquido}
															handleOpenModal={handleOpenModal}
															columns={columns}
														>
															<Row
																title="% Lucro ou Prejuízo Líquido/Faturamento Líquido"
																percentage={true}
																rows={lucroPrejuizoLiquidoPorcentagem}
																handleOpenModal={handleOpenModal}
																columns={columns}
															/>
															<Row
																isCollapse
																title="(-) Empréstimos, Aportes e Retiradas"
																rows={rowsEmprestimosHeader}
																rowsPercent={rowsEmprestimosHeaderPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsEmprestimos}
																	rowsPercent={rowsEmprestimosPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>
															<Row
																isCollapse
																title="(-) Reembolso"
																rows={reembolso}
																rowsPercent={reembolsoPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsReembolso}
																	rowsPercent={rowsReembolsoPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>
														</Row>
														<Row
															isCollapse
															backgroundColor={GRAY_LABEL_UX}
															color={WHITE_TABLE}
															colorTitle={WHITE_TABLE}
															fontWeight="bold"
															title="Resultado Líquido de Empréstimos, Aportes e Retiradas"
															rows={rowsLiquidoHeader}
															handleOpenModal={handleOpenModal}
															columns={columns}
														>
															<Row
																isCollapse
																title="(-) Outras Entradas e Saídas"
																rows={transferencia}
																rowsPercent={transferenciaPercent}
																handleOpenModal={handleOpenModal}
																columns={columns}
																percentActive={percentActive}
															>
																<RowData
																	rows={rowsTransferencia}
																	rowsPercent={rowsTransferenciaPercent}
																	handleOpenModal={handleOpenModal}
																	columns={columns}
																	percentActive={percentActive}
																/>
															</Row>

														</Row>

														{
															extraCalc && (
																<React.Fragment>
																	<Row
																		isCollapse
																		backgroundColor={GRAY_LABEL_UX}
																		color={WHITE_TABLE}
																		colorTitle={WHITE_TABLE}
																		fontWeight="bold"
																		title="R$ Custo de Entrega"
																		rows={custoEntrega}
																		handleOpenModal={handleOpenModal}
																		columns={columns}
																	>
																		<Row
																			backgroundColor={BLUE_LIGHTER_UX}
																			title="% Custo de Entrega"
																			rows={custoEntregaPctg}
																			handleOpenModal={handleOpenModal}
																			columns={columns}
																			percentage={true}
																		/>
																	</Row>
																	<Row
																		isCollapse
																		backgroundColor={GRAY_LABEL_UX}
																		color={WHITE_TABLE}
																		colorTitle={WHITE_TABLE}
																		fontWeight="bold"
																		title="R$ Vendas Delivery"
																		rows={vendasDelivery}
																		handleOpenModal={handleOpenModal}
																		columns={columns}
																	>
																		<Row
																			backgroundColor={BLUE_LIGHTER_UX}
																			title="% Vendas Delivery"
																			rows={vendasDeliveryPctg}
																			handleOpenModal={handleOpenModal}
																			columns={columns}
																			percentage={true}
																		/>
																	</Row>
																	<Row
																		isCollapse
																		backgroundColor={GRAY_LABEL_UX}
																		color={WHITE_TABLE}
																		colorTitle={WHITE_TABLE}
																		fontWeight="bold"
																		title="R$ Vendas Salão"
																		rows={vendasSalao}
																		handleOpenModal={handleOpenModal}
																		columns={columns}
																	>
																		<Row
																			backgroundColor={BLUE_LIGHTER_UX}
																			title="% Vendas Salão"
																			rows={vendasSalaoPctg}
																			handleOpenModal={handleOpenModal}
																			columns={columns}
																			percentage={true}
																		/>
																	</Row>
																	<Row
																		backgroundColor={GRAY_LABEL_UX}
																		color={WHITE_TABLE}
																		colorTitle={WHITE_TABLE}
																		fontWeight="bold"
																		title="Faturamento Total"
																		rows={faturamentoBrutoTotal}
																		handleOpenModal={handleOpenModal}
																		columns={columns}
																	/>
																</React.Fragment>
															)
														}
													</TableBody>
												</Table>
											</TableContainer>
										</Grid>
									</Grid>
								</React.Fragment>
							)
					)
					}
				</Paper>
			</div>
		);
	};

	export default DREConsolidada;
