export const dreConsolidadaNotDetalharList = ["Faturamento Bruto Total", "Faturamento Bruto de Itens", "(-) Devoluções e Cancelamentos",
    "(-) Descontos", "Faturamento Líquido de Itens", "Faturamento de Serviços (Bilheteria e Eventos)",
    "Faturamento Líquido Total", "(-) Taxas de Cartão e Apps de Delivery", "Markup", "(-) CMV - Custo da Mercadoria Vendida",
    "% CMV (CMV/Fat. Líquido de Itens)", "(-) Despesas com entregas", "% Despesas com entregas (Entregas/Fat. Líquido de Itens)",
    "Custos Variáveis", "% Custos Varíaveis/Fat. Líquido Total",
    "Margem de contribuição", "% Margem de Contribuição/Fat. Líquido de Itens", "Custos Fixos", "% Custos Fixos/Receita Bruta",
    "(-) Salários e Encargos", "(-) Despesas com Vendas e Marketing", "(-) Despesas Operacionais e Administrativas", "Lucro/Prejuízo Operacional",
    "% Lucro ou Prejuízo Operacional/Faturamento Líquido", "Ponto de Equilíbrio (Receita Bruta Necessária)", "(-) Investimentos",
    "Lucro/Prejuízo Líquido", "% Lucro ou Prejuízo Líquido/Faturamento Líquido", "(-) Empréstimos, Aportes e Retiradas",
    "Resultado Líquido de Empréstimos, Aportes e Retiradas", "(-) Outras Entradas e Saídas"
];

export const dreConsolidadaDetalharListIfood = [
    "(-) Frete Aplicativos",
    "(-) Serviços Logísticos",
    "(-) Pacote Anúncios Ifood",
];

export const labelsToPosition = {
    "faturamento": 423,
    "cmv (%)": 868,
    "cmv + cmo (%)": 868,
    "taxas de cartão e apps (%)": 551,
    "frete e entrega (%)": 1140,
    "impostos sobre vendas (%)": 491,
    "margem de contribuição e custo fixo (%)": 1472,
    "lucro operacional": 3382,
};